import { ReactNode, createContext, useContext, useState } from 'react';

interface TextEditorInformation {
  title: string,
  initialValue: string,
  onSave: (newText: string) => void
  onClose: () => void
}

interface TextEditorContextType {
  isTextEditorOpen: boolean,
  textEditorInformation: TextEditorInformation | null
  openTextEditor: (title: string, initialValue: string, onSave: (newText: string) => void, onClose: () => void) => void
  closeTextEditor: () => void
}

const TextEditorContext = createContext<TextEditorContextType | undefined>(undefined)

const TextEditorProvider = ({ children }: { children: ReactNode }) => {
  const [textEditorInformation, setTextEditorInformation] = useState<null | TextEditorInformation>(null)
  const isTextEditorOpen = textEditorInformation !== null

  const openTextEditor = (title: string, initialValue: string, onSave: (newText: string) => void, onClose: () => void) => {
    setTextEditorInformation({ title, initialValue, onSave, onClose })
  }

  const closeTextEditor = () => {
    setTextEditorInformation(null)
  }

  return <TextEditorContext.Provider value={{ isTextEditorOpen, textEditorInformation, openTextEditor, closeTextEditor }}>{children}</TextEditorContext.Provider>
}

// Define a custom hook to use the chat context
const useTextEditor = () => {
  const context = useContext(TextEditorContext)
  if (!context) {
    throw new Error('useTextEditor must be used within a TextEditor')
  }
  return context
}

export { TextEditorProvider, useTextEditor };

