import { ActionApplication } from "../actions/ActionApplication"
import { ActionExecutionApi, AgentActionParameters } from "../actions/domain"
import { Chat, ChatContent, MessagePath } from "../chat/domain"
import { onMessageComplete, onMessageContent } from "../chat/useAIChat"
import { ParsingHandler, createAiStreamParser } from "./AiStreamParser"
import { AssistantApplication } from "./AssistantApplication"
import { Assistant, AssistantProperty, AssistantSection } from "./domain"

export interface ActionListenerProps {
  assistant: Assistant
  currentChat: Chat
  addContent: (chatId: string, sectionId: string, messageId: string, content: ChatContent) => void
  appendMessage: (chatId: string, sectionId: string, messageId: string, delta: string) => void
  updateMessage: (chatId: string, sectionId: string, messageId: string, complete: boolean) => void
  addVersion: (assistantId: string, field: AssistantProperty, initialValue: string) => void
  selectVersion: (assistantId: string, field: AssistantProperty, versionIndex: number) => void
  appendToLatestVersion: (assistantId: string, field: AssistantProperty, delta: string) => void
  cleanUpLatestVersion: (assistantId: string, field: AssistantProperty) => void
  setCurrentSectionId: (sectionId: string) => void
  createNewSection: (assistantSection: AssistantSection) => void
  reportInvalidTagParameters: (error: string[]) => void
}

export const createActionListeners = ({assistant, currentChat, addVersion, selectVersion, appendToLatestVersion, cleanUpLatestVersion, setCurrentSectionId, createNewSection, addContent, appendMessage, updateMessage, reportInvalidTagParameters}: ActionListenerProps) => {
  const actionExecutionApi: ActionExecutionApi = {
    assistant: {
      addVersion: (property: AssistantProperty, initialValue: string) => addVersion(assistant.id, property, initialValue),
      selectVersion: (property: AssistantProperty, versionIndex: number) => selectVersion(assistant.id, property, versionIndex),
      appendToLatestVersion: (property: AssistantProperty, delta: string) => appendToLatestVersion(assistant.id, property, delta),
      cleanUpLatestVersion: (property: AssistantProperty) => cleanUpLatestVersion(assistant.id, property),
      findSection: (sectionName: AssistantSection) => {
        const sectionInfo = AssistantApplication.getSectionInformation(sectionName)
        return currentChat.sections.find(s => s.name === sectionInfo.name)?.id ?? ''
      },
      createSection: (sectionName: AssistantSection) => createNewSection(sectionName),
      selectSection: (sectionId: string) => setCurrentSectionId(sectionId)
    },
    chat: {
      addContent: (path: MessagePath, content: ChatContent) => addContent(path.chatId, path.sectionId, path.messageId, content),
      appendMessage: (path: MessagePath, delta: string) => appendMessage(path.chatId, path.sectionId, path.messageId, delta),
      updateMessage: (path: MessagePath, complete: boolean) => updateMessage(path.chatId, path.sectionId, path.messageId, complete)
    }
  }

  const parseHandler: ParsingHandler = {
    onAppendMessage: (info: MessagePath, delta: string) => appendMessage(info.chatId, info.sectionId, info.messageId, delta),
    onNewTag: (info: MessagePath, tag: string, parameters: AgentActionParameters) => {
      ActionApplication.onNewTag(actionExecutionApi, info, tag, parameters)
    },
    onUpdateTagContent: (info: MessagePath, tag: string, delta: string) => {
      ActionApplication.onUpdateTagContent(actionExecutionApi, info, tag, delta)
    },
    onCloseTag: (info: MessagePath, tag: string) => {
      ActionApplication.onCloseTag(actionExecutionApi, info, tag)
    },
    onInvalidTagParameters: (info: MessagePath, tag: string, parameters: AgentActionParameters, reasons: string[]) => {
      reportInvalidTagParameters(reasons)
    }
  }

  const onMessageContent: onMessageContent = createAiStreamParser(parseHandler)

  const onCompleteMessage: onMessageComplete = (chatId: string, sectionId: string, messageId: string, abort: boolean) => {
    ActionApplication.onMessageComplete(actionExecutionApi, {chatId, sectionId, messageId}, abort)
  }
  
  return {onMessageContent, onCompleteMessage}
}
