import IonIcon from '@reacticons/ionicons'
import { useState } from 'react'
import { CustomMarkdown } from '../../../shared/components/CustomMarkdown'
import { ChatThinkingContent } from '../../domain'
import './ThinkingComponent.scss'

interface ThinkingComponentProps {
  content: ChatThinkingContent
  isInCreation: boolean
  agentName: string
}

export const ThinkingComponent = ({ content, isInCreation, agentName }: ThinkingComponentProps) => {
  const [expanded, setExpanded] = useState(false)
  const isOpen = expanded || isInCreation

  const toggleExpanded = () => {
    if (!isInCreation) {
      setExpanded(!expanded)
    }
  }

  return <div className={`thinking`}>
      <div className={`thinking__header thinking__header--${isInCreation ? 'non-clickable' : 'clickable'}`} onClick={toggleExpanded}>{!isInCreation && <IonIcon name={`chevron-${isOpen ? 'down' : 'forward'}-outline`}></IonIcon>} {isInCreation ? 'Thinking...' : `${agentName}'s Thoughts`}</div>
      <div className={`thinking__content thinking__content--${isInCreation ? 'creating' : 'created'} thinking__content--${isOpen ? 'visible' : 'hidden'}`}><CustomMarkdown>{content.text}</CustomMarkdown></div>
    </div>
}
