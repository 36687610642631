import { AgentActionDefinition, AgentActionParameterDescription, AgentActionParameters, AgentActionTag } from "../actions/domain";
import { MessagePath } from "../chat/domain";

export interface ParsingHandler {
  onAppendMessage: (info: MessagePath, delta: string) => void
  onNewTag: (info: MessagePath, tag: string, parameters: AgentActionParameters) => void
  onUpdateTagContent: (info: MessagePath, tag: string, delta: string) => void
  onCloseTag: (info: MessagePath, tag: string) => void
  onInvalidTagParameters: (info: MessagePath, tag: string, parameters: AgentActionParameters, reasons: string[]) => void
}

const validTags = Object.keys(AgentActionTag);

const validateParameters = (tag: string, params: AgentActionParameters): string[] => {
  const parameterInformation: AgentActionParameterDescription[] = AgentActionDefinition[tag as AgentActionTag];
  if (parameterInformation) {
    let errorCollection: string[] = []
    const allRequiredParameters = new Set(parameterInformation.filter(p => p.required).map(p => p.name));
    const allPresentParameters = Object.keys(params)
    allPresentParameters.forEach(key => {
      const value = params[key]
      const parameterInfo = parameterInformation.find(pi => pi.name === key)
      if (!parameterInfo) {
        errorCollection = [...errorCollection, `- Tag ${tag} has no parameter ${key}!`]
      } else {
        allRequiredParameters.delete(key)
        if (!parameterInfo.validFunction(key, value)) {
          errorCollection = [...errorCollection, `- The value of the parameter ${key} in tag ${tag} has an invalid value '${value}'.`]
        }
      }
    })
    if (allRequiredParameters.size > 0) {
      errorCollection = [...errorCollection, `- Tag ${tag} is missing required parameters: ${Array.from(allRequiredParameters).join(', ')}`]
    }
    return errorCollection
  }
  return [`- Tag ${tag} is invalid!`]
}

const parseTagParams = (paramString: string): AgentActionParameters => {
  const params: AgentActionParameters = {};
  const regex = /(\w+)='(.*?)'/g;
  let match: RegExpExecArray | null;
  while ((match = regex.exec(paramString))) {
    params[match[1]] = match[2];
  }
  return params;
};

const handleOpeningTag = (info: MessagePath, tagBuffer: string, messageApi: ParsingHandler): string => {
  const match = tagBuffer.match(/^<(\w+)\s*(.*)>$/);
  if (match) {
    const tagName = match[1];
    if (validTags.includes(tagName)) {
      const tagParams = parseTagParams(match[2]);
      const parameterErrors = validateParameters(tagName, tagParams);
      if (parameterErrors.length > 0) {
        messageApi.onInvalidTagParameters(info, tagName, tagParams, parameterErrors);
      } else {
        messageApi.onNewTag(info, tagName, tagParams);
      }
      return tagName
    } else {
      messageApi.onAppendMessage(info, tagBuffer);
    }
  } else {
    messageApi.onAppendMessage(info, tagBuffer);
  }
  return ''
}

const handleClosingTag = (info: MessagePath, tagBuffer: string, currentTags: string[], messageApi: ParsingHandler): boolean => {
  const match = tagBuffer.match(/^<\/(\w+)>$/);
  if (match) {
    const tagName = match[1];
    if (currentTags.length > 0 && currentTags[currentTags.length - 1] === tagName) {
      messageApi.onCloseTag(info, tagName);
      return true
    } else {
      messageApi.onAppendMessage(info, tagBuffer);
    }
  } else {
    messageApi.onAppendMessage(info, tagBuffer);
  }
  return false
}

export const createAiStreamParser = (parsingHandler: ParsingHandler) => {
  const buffer: string[] = [];
  let inTag = false;
  let currentTags: string[] = [];
  let tagBuffer = '';
  let tagContentBuffer = '';

  const processBuffer = (info: MessagePath) => {
    while (buffer.length > 0) {
      const char = buffer.shift()!;

      if (inTag) {
        if (char === '>') {
          tagBuffer += char;
          inTag = false;

          const isOpeningTag = tagBuffer.startsWith('<') && !tagBuffer.startsWith('</');
          const isClosingTag = tagBuffer.startsWith('</');

          if (isOpeningTag) {
            const tag = handleOpeningTag(info, tagBuffer, parsingHandler)
            currentTags = tag ? [...currentTags, tag] : currentTags
          } else if (isClosingTag) {
            if (handleClosingTag(info, tagBuffer, currentTags, parsingHandler)) {
              currentTags = currentTags.slice(0, -1)
            }
          }
          tagBuffer = '';
        } else {
          tagBuffer += char;
        }
      } else {
        if (char === '<') {
          if (tagContentBuffer.length > 0) {
            parsingHandler.onAppendMessage(info, tagContentBuffer);
            tagContentBuffer = '';
          }
          tagBuffer += char;
          inTag = true;
        } else {
          if (currentTags.length > 0) {
            parsingHandler.onUpdateTagContent(info, currentTags[currentTags.length - 1], char)
          } else {
            tagContentBuffer += char;
          }
        }
      }
    }

    if (tagContentBuffer.length > 0) {
      parsingHandler.onAppendMessage(info, tagContentBuffer);
      tagContentBuffer = '';
    }
  };

  return (chatId: string, sectionId: string, messageId: string, delta: string) => {
    buffer.push(...delta.split(''));
    processBuffer({chatId, sectionId, messageId});
  };
}
