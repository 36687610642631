import { AssistantApplication } from "../../../assistant/AssistantApplication"
import { Assistant, AssistantProperty } from "../../../assistant/domain"
import { ChatContent, ChatPropertyContent } from "../../domain"


export const PropertyContentApplication = {
  create: (key: AssistantProperty): ChatPropertyContent => {
    return { type: 'property', key }
  },
  append: (content: ChatContent, delta: string): ChatContent => {
    return content
  },
  convertToPrompt: (content: ChatContent, assistant: Assistant): string => {
    return AssistantApplication.getVersion(assistant, (content as ChatPropertyContent).key) ?? ''
  }
}
