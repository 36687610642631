import { v4 as uuidv4 } from 'uuid';
import { Agent, AgentContextFunction } from '../agents/domain';
import { AnnaAssistantAssambler } from '../agents/team/Anna Assistant Assembler';
import { NeoNamer } from '../agents/team/Neo Namer';
import { PeerPurposePerfecter } from '../agents/team/Peer Purpose Perfecter';
import { RobRoleRefiner } from '../agents/team/Rob Role Refiner';
import { TeaTaskTalker } from '../agents/team/Tea Task Talker';
import { Assistant, AssistantProperty, AssistantSection, AssistantSectionName, AvailableAssistantImages, PropertyName, SimplifiedAssistant } from "./domain";
import { DragonImages } from '../shared/components/DragonImage';
import { CesarContextCreator } from '../agents/team/Cesar Context Creator';
import { SaraScoper } from '../agents/team/Sara Scoper';
import { EvaEngager } from '../agents/team/Eva Engager';
import { WillWritingWizard } from '../agents/team/Will Writing Wizard';
import { CarlCommunicationCaptain } from '../agents/team/Carl Communication Captain';

const getVersion = (assistant: Assistant, field: AssistantProperty, versionIndex: number = -1) => {
  const versionInfo = assistant.data[field]
  if (!versionInfo) {
    return ''
  }
  const realVersion = versionIndex === -1 ? versionInfo.selectedVersionIndex : Math.max(0, Math.min(versionInfo.versions.length - 1, versionIndex))
  return versionInfo.versions[realVersion] ?? ''
}

const simplify = (assistant: Assistant): SimplifiedAssistant => {
  return {
    id: assistant.id,
    image: assistant.image,
    [AssistantProperty.Purpose]: getVersion(assistant, AssistantProperty.Purpose),
    [AssistantProperty.Role]: getVersion(assistant, AssistantProperty.Role),
    [AssistantProperty.RoleDescription]: getVersion(assistant, AssistantProperty.RoleDescription),
    [AssistantProperty.Name]: getVersion(assistant, AssistantProperty.Name),
    [AssistantProperty.Tasks]: getVersion(assistant, AssistantProperty.Tasks),
    [AssistantProperty.Context]: getVersion(assistant, AssistantProperty.Context),
    [AssistantProperty.UserEngagement]: getVersion(assistant, AssistantProperty.UserEngagement),
    [AssistantProperty.Boundaries]: getVersion(assistant, AssistantProperty.Boundaries),
    [AssistantProperty.WritingStyle]: getVersion(assistant, AssistantProperty.WritingStyle),
    [AssistantProperty.Tone]: getVersion(assistant, AssistantProperty.Tone)
  }
}

const createEmptyVersionInfo = () => {
  return {
    versions: [],
    selectedVersionIndex: -1
  }
}

const create = (chatId: string): Assistant => {
  return {
    id: 'assistant-'+uuidv4(),
    lastUpdated: Date.now(),
    image: undefined,
    chat: {
      constructionChatId: chatId,
      testChatIds: []
    },
    data: {
      [AssistantProperty.Purpose]: createEmptyVersionInfo(),
      [AssistantProperty.Role]: createEmptyVersionInfo(),
      [AssistantProperty.RoleDescription]: createEmptyVersionInfo(),
      [AssistantProperty.Name]: createEmptyVersionInfo(),
      [AssistantProperty.Tasks]: createEmptyVersionInfo(),
      [AssistantProperty.Context]: createEmptyVersionInfo(),
      [AssistantProperty.UserEngagement]: createEmptyVersionInfo(),
      [AssistantProperty.Boundaries]: createEmptyVersionInfo(),
      [AssistantProperty.WritingStyle]: createEmptyVersionInfo(),
      [AssistantProperty.Tone]: createEmptyVersionInfo(),
    }
  }
}

const getSectionInformation = (assistantSection: AssistantSection): {name: string, agent: Agent} => {
  const name = AssistantSectionName(assistantSection)
  switch (assistantSection) {
    case AssistantSection.start:
      return { name, agent: AnnaAssistantAssambler }
    case AssistantSection.purpose:
      return { name, agent: PeerPurposePerfecter }
    case AssistantSection.role:
      return { name, agent: RobRoleRefiner }
    case AssistantSection.name:
      return { name, agent: NeoNamer }
    case AssistantSection.tasks:
      return { name, agent: TeaTaskTalker }
    case AssistantSection.context:
      return { name, agent: CesarContextCreator }
    case AssistantSection.engagement:
      return { name, agent: EvaEngager }
    case AssistantSection.scope:
      return { name, agent: SaraScoper }
    case AssistantSection.writing:
      return { name, agent: WillWritingWizard }
    case AssistantSection.tone:
      return { name, agent: CarlCommunicationCaptain }
  }
}

const createPromptOfProperty = (property: AssistantProperty, assistant: SimplifiedAssistant, sectionDescription: string) => {
  const propertyContent = assistant[property]
  return propertyContent ? `# ${PropertyName(property)}${!!sectionDescription ? '\n'+sectionDescription : ''}
  ${propertyContent}\n\n` : ''
}

const createInstructionPrompt = (assistant: SimplifiedAssistant) => {
  return `You are '${assistant[AssistantProperty.Name]}', an AI assistant customized for a specific use case. Here are your instructions:

  # Purpose
  ${assistant[AssistantProperty.Purpose]}

  # Role
  You are a ${assistant[AssistantProperty.Role]}. ${assistant[AssistantProperty.RoleDescription]}

  ${createPromptOfProperty(AssistantProperty.Tasks, assistant, 'These are common tasks I would like you to help me with:')}
  ${createPromptOfProperty(AssistantProperty.Context, assistant, 'This section contains information about the context that will help you to excel on the tasks.')}
  ${createPromptOfProperty(AssistantProperty.UserEngagement, assistant, 'This section describes how you should interact with the user.')}
  ${createPromptOfProperty(AssistantProperty.Boundaries, assistant, 'This section describes your scope and also your boundaries.')}
  ${createPromptOfProperty(AssistantProperty.WritingStyle, assistant, 'Whenever you produce a deliverable (outcome of your tasks), you must apply the following writing style and format!')}
  ${createPromptOfProperty(AssistantProperty.Tone, assistant, 'Whenever communicate with me (not producing a deliverable), use this tone of voice for our communication.')}
  `
}


const createAgent = (assistant: Assistant): Agent => {
  const simplifiedAssistant = simplify(assistant)
  const systemPrompt = createInstructionPrompt(simplifiedAssistant)
  return {
    id: 'agent-'+uuidv4(),
    name: simplifiedAssistant[AssistantProperty.Name],
    description: simplifiedAssistant[AssistantProperty.Purpose],
    image: assistant.image ?? DragonImages.NO_DRAGON,
    systemPrompt: (agentContextFunction: AgentContextFunction) => systemPrompt
  }
}

const selectAssistantImage = () => {
  return AvailableAssistantImages[Math.floor(Math.random() * AvailableAssistantImages.length)]
}


const createAgentContextFunction = (assistant: Assistant): AgentContextFunction => {
  const simplifiedAssistant = simplify(assistant)
  return (key: string) => {
    if (Object.values(AssistantProperty).includes(key as AssistantProperty)) {
      return simplifiedAssistant[key as AssistantProperty]
    }
    return ''
  }
}

export const AssistantApplication = {
  create,
  simplify,
  getVersion,
  getSectionInformation,
  createInstructionPrompt,
  createAgent,
  selectAssistantImage,
  createAgentContextFunction
}