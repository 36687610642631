import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../pages/Routes'
import "./NoKeyWarning.scss"

export const NoKeyWarning = () => {
  const navigate = useNavigate()

  const onSettings = () => {
    navigate(AppRoutes.Settings())
  }

  return <div className='no-key-warning'>There is no OpenAI API key saved. To be able to use the chat function, set an OpenAI API key in <button className='u-link' onClick={onSettings}>Settings</button>.</div>
}
