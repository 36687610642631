import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AgentApplication } from '../agents/AgentApplication'
import { useAiInfrastructure } from '../aiInfrastructure/AiInfrastructureContext'
import { AiInfrastructureApplication } from '../aiInfrastructure/AiInstrastructureApplication'
import { ApiKeyForm } from '../aiInfrastructure/components/ApiKeyForm'
import { DragonImage, DragonImages } from '../shared/components/DragonImage'
import { Spinner } from '../shared/components/Spinner'
import { AppRoutes } from './Routes'
import "./WelcomePage.scss"

export const WelcomePage = () => {
  const [busy, setBusy] = useState(false)
  const {hasKey, setKey} = useAiInfrastructure()
  const navigate = useNavigate()
  const location = useLocation()
  const pin = location.hash.substring(1)

  useEffect(() => {
    const loadByPin = async () => {
      setBusy(true)
      try {
        const apiKey = await AiInfrastructureApplication.loadKeyByPin(pin)
        setKey(apiKey)
      } catch (e) {
      } finally {
        setBusy(false)
      }
    }
    if (/^[0-9]{4}$/.test(pin)) {
      loadByPin()
    }
    // this effect will be called only once on creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className='welcome-page'>
    {busy && <Spinner></Spinner>}
      {!busy && <div className='welcome-page__welcome'>
        <DragonImage dragon={DragonImages.DRAGON_1} size={128}></DragonImage>
        <p>Hello, I am {AgentApplication.agentLead.name}. I am helping you to create teams of assistants.</p>
        {!hasKey && <p>Before I can work my magic, I need the 4-digit event PIN or, if you want to use my services outside of an event, you can also enter an OpenAI API key.</p>}
        {hasKey && <p>Everything is setup, so you can go ahead and create your first assistant.</p>}
      </div>
      }
      {!busy && !hasKey && <ApiKeyForm></ApiKeyForm>}
      {!busy && hasKey && <button className='primary' onClick={() => navigate(AppRoutes.Overview())}>Continue</button>}
    </div>
}
