import IonIcon from "@reacticons/ionicons"
import { useNavigate } from "react-router-dom"
import { useAiInfrastructure } from "../aiInfrastructure/AiInfrastructureContext"
import { NoKeyWarning } from "../aiInfrastructure/components/NoKeyWarning"
import { useAssistants } from "../assistant/AssistantContext"
import { AssistantList } from "../assistant/components/AssistantList"
import { useAssistantManager } from "../assistant/useAssistantManager"
import "./OverviewPage.scss"
import { AppRoutes } from "./Routes"

export const OverviewPage = () => {
  const {hasKey, isInitialized} = useAiInfrastructure()
  const { createAssistant } = useAssistantManager()
  const {assistantState} = useAssistants()
  const navigate = useNavigate()

  const onCreate = () => {
    const assistant = createAssistant()
    
    navigate(AppRoutes.Create(assistant))
  }

  const onSettings = () => {
    navigate(AppRoutes.Settings())
  }

  return <div className='overview-page u-page'>
    <header>
      <button className='overview-page__back u-link u-icon' onClick={onSettings}><IonIcon name="settings-outline"></IonIcon></button>
      <h2>Your Assistants</h2>
      <button className={`overview-page__test-button u-link u-icon`} onClick={onCreate}><IonIcon name='add-circle-outline'></IonIcon></button>
    </header>
    <main>
      <AssistantList assistants={Object.values(assistantState.assistants)}></AssistantList>
    </main>
    {isInitialized && !hasKey && <footer><NoKeyWarning></NoKeyWarning></footer>}
    </div>
}
