import { Assistant } from "../../../assistant/domain"
import { ChatContent, ChatTextContent } from "../../domain"

export const TextContentApplication = {
  create: (message: string): ChatTextContent => {
    return { type: 'text', text: message }
  },
  append: (content: ChatContent, delta: string): ChatContent => {
    return {...(content as ChatTextContent), text: (content as ChatTextContent).text + delta} as ChatTextContent
  },
  convertToPrompt: (content: ChatContent, assistant: Assistant): string => {
    return (content as ChatTextContent).text
  }
}