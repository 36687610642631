import IonIcon from '@reacticons/ionicons'
import { Agent, AgentContextFunction } from '../domain'
import { AgentDetails } from './AgentDetails'
import "./AgentProfile.scss"
import { AgentPromptBar } from './AgentPromptBar'

interface AgentProfileProps {
  agent: Agent
  onClose: () => void
}

const agentContextFunction: AgentContextFunction = (key: string) => (`\${Assistant.${key}}`)

export const AgentProfile = ({agent, onClose}: AgentProfileProps) => {

  return (
        <div className={`agent-profile u-overlay`}>
          <header className='agent-profile__header'>
            <div className='u-header-placeholder'></div>
            <h2>{agent.name}</h2>
            <button className='agent-profile__close u-link u-icon' onClick={onClose}><IonIcon name="close"></IonIcon></button>
          </header>
          <main>
            <AgentDetails agent={agent} agentContextFunction={agentContextFunction} showDescription={true}></AgentDetails>
          </main>
          <footer className='agent-profile__footer'>
            <AgentPromptBar agent={agent} agentContextFunction={agentContextFunction}></AgentPromptBar>
          </footer>
        </div>
  )
}
