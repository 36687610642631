import { ChatCompletionAssistantMessageParam, ChatCompletionMessageParam, ChatCompletionUserMessageParam } from "openai/resources"
import { Assistant } from "../../assistant/domain"
import { BotMessage, ChatContent, ChatMessage, UserMessage } from "../domain"
import { ChatContentApplication } from "./ChatContentApplication"
import { v4 as uuidv4 } from 'uuid';

const createUserMessage = (message: string): UserMessage => {
  return {
      id: 'user-' + uuidv4(),
      content: [ChatContentApplication.TextContentApplication.create(message) ],
      sender: 'user',
      senderName: 'You',
      timestamp: Date.now()
    }
}

const createBotMessage = (message: string, botName: string, activity: 'Writing' | 'Thinking', joiningAgentId: string | undefined = undefined, completed: boolean = false): BotMessage => {
  return createBotComplexMessage([ChatContentApplication.TextContentApplication.create(message)], botName, activity, joiningAgentId, completed)
}

const createBotComplexMessage = (content: ChatContent[], botName: string, activity: 'Writing' | 'Thinking', joiningAgentId: string | undefined = undefined, completed: boolean = false): BotMessage => {
  return {
      id: 'bot-' + uuidv4(),
      content,
      sender: 'bot',
      senderName: botName,
      activity,
      joiningAgentId,
      completed,
      timestamp: Date.now()
    }
}

const addContentToMessage = (message: ChatMessage, content: ChatContent) => {
  return {...message, content: [...message.content, content]}
}

const convertToPrompt = (message: ChatMessage, assistant: Assistant): ChatCompletionMessageParam => {
  const convertedContent = message.content.map(content => ChatContentApplication.convertToPrompt(content, assistant))
  const content = convertedContent.join('\n')
  switch (message.sender) {
    case 'bot':
      return { role: 'assistant', content } as ChatCompletionAssistantMessageParam
    case 'user':
      return { role: 'user', content } as ChatCompletionUserMessageParam
  }
}

export const ChatMessageApplication = {
  createUserMessage,
  createBotMessage,
  createBotComplexMessage,
  addContentToMessage,
  convertToPrompt
}