import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAssistants } from "../assistant/AssistantContext"
import "./RunPage.scss"
import { AppRoutes } from "./Routes"
import { AgentChat } from "../agents/components/AgentChat"
import { AssistantApplication } from "../assistant/AssistantApplication"
import { useChat } from "../chat/ChatContext"
import { useAIChat } from "../chat/useAIChat"
import { Chat, ChatSection } from "../chat/domain"

let lastTimeChatWasInitialized = 0

export const RunPage = () => {
  const { assistantId } = useParams()
  const { assistantState } = useAssistants()
  const { chatState } = useChat()
  const { createChat, createSection } = useAIChat()
  const currentAssistant = assistantState.assistants[assistantId ?? '']
  const navigate = useNavigate()
  const agent = currentAssistant ? AssistantApplication.createAgent(currentAssistant) : undefined
  const [chat, setChat] = useState<Chat | undefined>(currentAssistant ? chatState.chats[currentAssistant.chat.testChatIds[currentAssistant.chat.testChatIds.length - 1]] : undefined)
  const [section, setSection] = useState<ChatSection | undefined>(chat ? chat.sections[0] : undefined)
  const [isChatInitialized, setChatInitialized] = useState<boolean>(!!chat && !!section)
  
  useEffect(() => {
    if (!agent) {
      navigate(AppRoutes.Overview())
    } else if (!isChatInitialized && Date.now() - lastTimeChatWasInitialized > 500) {
      lastTimeChatWasInitialized = Date.now()
      setChatInitialized(true)
      const newChat = createChat()
      const newSection = createSection(newChat.id, 'Chat', agent, () => agent)
      setChat(newChat)
      setSection(newSection)
    }
    // this effect will be called only once on creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return agent && chat && section ? <AgentChat agent={agent} chatId={chat.id} sectionId={section.id}></AgentChat> : <div></div>
}
