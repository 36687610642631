import { DragonImages } from "../shared/components/DragonImage"

export type AgentContextFunction = (key:string) => string

export enum AgentName {
  AnnaAssistantAssambler = 'Anna Assistant Assambler',
  CarlCommunicationCaptain = 'Carl Communication Captain',
  CesarContextCreator = 'Cesar Context Creator',
  EvaEngager = 'Eva Engager',
  NeoNamer = 'Neo Namer',
  PeerPurposePerfecter = 'Peer Purpose Perfecter',
  RobRoleRefiner = 'Rob Role Refiner',
  SaraScoper = 'Sara Scoper',
  TeaTaskTalker = 'Tea Task Talker',
  WillWritingWizard = 'Will Writing Wizard'
}

export interface Agent {
  id: string,
  name: string,
  description: string,
  image: DragonImages,
  systemPrompt: (getInfo: AgentContextFunction) => string,
  initialMessage?: string,
  firstInteraction?: string
}

export type AgentRetrievalFunction = (id: string) => Agent | undefined