import { ReactNode, createContext, useContext, useReducer } from 'react';
import { AssistantProperty, Assistant, AssistantVersionInfo } from './domain';
import { DragonImages } from '../shared/components/DragonImage';

const LOCAL_STORAGE_KEY = 'assistant__key'

// Define the ChatState interface
export interface AssistantState {
  assistants: {[id: string]: Assistant};
}

// Define the Action types
type Action =
  | { type: 'ADD_ASSISTANT', assistant: Assistant }
  | { type: 'DELETE_ASSISTANT', assistantId: string }
  | { type: 'UPDATE_IMAGE', assistantId: string, image: DragonImages | undefined }
  | { type: 'ADD_VERSION', assistantId: string, field: AssistantProperty, initialValue: string }
  | { type: 'SELECT_VERSION', assistantId: string, field: AssistantProperty, versionIndex: number }
  | { type: 'APPEND_TO_LATEST_VERSION', assistantId: string, field: AssistantProperty, delta: string }
  | { type: 'CLEAN_UP_LATEST_VERSION', assistantId: string, field: AssistantProperty }

const updateAssistantInState = (state: AssistantState, assistant: Assistant) => {
  return {...state, assistants: {...state.assistants, [assistant.id]: assistant}}
}

const updateAssistant = (state: AssistantState, assistantId: string, manipulate: (assistant: Assistant) => Assistant) => {
  const assistant = state.assistants[assistantId]
  if (assistant) {
    const newAssistant = manipulate(assistant)
    return updateAssistantInState(state, newAssistant)
  }
  return state
}

const updateVersionInAssistant = (state: AssistantState, assistantId: string, field: AssistantProperty, manipulate: (versionInfo: AssistantVersionInfo) => AssistantVersionInfo) => {
  return updateAssistant(state, assistantId, (assistant) => {
    const versionInfo = assistant.data[field]
    if (versionInfo) {
      const newVersionInfo = manipulate(versionInfo)
      return {...assistant, data: {...assistant.data, [field]: newVersionInfo}, lastUpdated: Date.now()}
    }
    return assistant
  })
}

const updateLocalStorage = (state: AssistantState) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
  return state
}

// Define the chatReducer function
const assistantDataReducer = (state: AssistantState, action: Action): AssistantState => {
  switch (action.type) {
    case 'ADD_ASSISTANT':
      return updateLocalStorage(updateAssistantInState(state, action.assistant))
    case 'DELETE_ASSISTANT':
      return updateLocalStorage({...state, assistants: Object.fromEntries(Object.entries(state.assistants).filter(([key]) => key !== action.assistantId))})
    case 'UPDATE_IMAGE':
      return updateLocalStorage(updateAssistant(state, action.assistantId, (assistant) => ({...assistant, image: action.image})))
    case 'ADD_VERSION':
      return updateLocalStorage(updateVersionInAssistant(state, action.assistantId, action.field, (versionInfo) => {
        const versions = versionInfo.versions
        return {...versionInfo, versions: [...versions, action.initialValue], selectedVersionIndex: versions.length}
      }))
    case 'SELECT_VERSION':
      return updateLocalStorage(updateVersionInAssistant(state, action.assistantId, action.field, (versionInfo) => {
        return {...versionInfo, selectedVersionIndex: (action.versionIndex >= 0 && action.versionIndex < versionInfo.versions.length ? action.versionIndex : versionInfo.selectedVersionIndex)}
      }))
    case 'APPEND_TO_LATEST_VERSION':
      return updateLocalStorage(updateVersionInAssistant(state, action.assistantId, action.field, (versionInfo) => {
        const versions = versionInfo.versions.length === 0 ? [''] : versionInfo.versions
        const latestVersionindex = versions.length - 1
        return {...versionInfo, versions: versions.map((version, index) => (index === latestVersionindex) ? version + (version === '' ? action.delta.trimStart() : action.delta) : version)}
      }))
    case 'CLEAN_UP_LATEST_VERSION':
      return updateLocalStorage(updateVersionInAssistant(state, action.assistantId, action.field, (versionInfo) => {
        const versions = versionInfo.versions.length === 0 ? [''] : versionInfo.versions
        const latestVersionindex = versions.length - 1
        return {...versionInfo, versions: versions.map((version, index) => (index === latestVersionindex) ? version.trim() : version)}
      }))
    default:
      throw new Error('Unhandled action type')
  }
}

interface AssistantContextType {
  assistantState: AssistantState
  addAssistant: (assistant: Assistant) => void
  deleteAssistant: (assistantId: string) => void
  updateImage: (assistantId: string, image: DragonImages | undefined) => void
  addVersion: (assistantId: string, field: AssistantProperty, initialValue: string) => void
  selectVersion: (assistantId: string, field: AssistantProperty, versionIndex: number) => void
  appendToLatestVersion: (assistantId: string, field: AssistantProperty, delta: string) => void
  cleanUpLatestVersion: (assistantId: string, field: AssistantProperty) => void
}

const AssistantContext = createContext<AssistantContextType | undefined>(undefined)

const AssistantProvider = ({ children }: { children: ReactNode }) => {
  const [assistantState, dispatch] = useReducer(assistantDataReducer, { assistants: {}, ...localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!) : {} })
  
  const addAssistant = (assistant: Assistant) => {
    dispatch({ type: 'ADD_ASSISTANT', assistant })
  }

  const deleteAssistant = (assistantId: string) => {
    dispatch({ type: 'DELETE_ASSISTANT', assistantId })
  }

  const updateImage = (assistantId: string, image: DragonImages | undefined) => {
    dispatch({ type: 'UPDATE_IMAGE', assistantId, image })
  }

  const addVersion = (assistantId: string, field: AssistantProperty, initialValue: string) => {
    dispatch({ type: 'ADD_VERSION', assistantId, field, initialValue })
  }

  const selectVersion = (assistantId: string, field: AssistantProperty, versionIndex: number) => {
    dispatch({ type: 'SELECT_VERSION', assistantId, field, versionIndex })
  }

  const appendToLatestVersion = (assistantId: string, field: AssistantProperty, delta: string) => {
    dispatch({ type: 'APPEND_TO_LATEST_VERSION', assistantId, field, delta })
  }

  const cleanUpLatestVersion = (assistantId: string, field: AssistantProperty) => {
    dispatch({ type: 'CLEAN_UP_LATEST_VERSION', assistantId, field })
  }

  return <AssistantContext.Provider value={{ assistantState, addAssistant, deleteAssistant, updateImage, addVersion, selectVersion, appendToLatestVersion, cleanUpLatestVersion }}>{children}</AssistantContext.Provider>
}

// Define a custom hook to use the chat context
const useAssistants = () => {
  const context = useContext(AssistantContext)
  if (!context) {
    throw new Error('useAssistant must be used within a AssistantContext')
  }
  return context
}

export { AssistantProvider, useAssistants };

