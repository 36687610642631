import IonIcon from '@reacticons/ionicons'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../pages/Routes'
import { DragonImage, DragonImages } from '../../shared/components/DragonImage'
import { AssistantApplication } from '../AssistantApplication'
import { Assistant, AssistantProperty } from '../domain'
import { useAssistantManager } from '../useAssistantManager'
import "./AssistantItem.scss"

interface AssistantItemProps {
  assistant: Assistant
}

export const AssistantItem = ({assistant}: AssistantItemProps) => {
  const {removeAssistant} = useAssistantManager()
  const navigate = useNavigate()
  const simplifiedAssistant = AssistantApplication.simplify(assistant)

  const onRun = () => {
    navigate(AppRoutes.Run(assistant))
  }

  const onEdit = () => {
    navigate(AppRoutes.Create(assistant))
  }

  const onDelete = () => {
    const result = window.confirm(`Are you sure you want to delete the assistant "${simplifiedAssistant[AssistantProperty.Name] || 'No Name'}"?`)
    if (result) {
      removeAssistant(assistant.id)
    }
  }

  return <div className='assistant-item'>
      <DragonImage dragon={assistant.image ?? DragonImages.NO_DRAGON} size={64}></DragonImage>
      <div className='assistant-item__content'>
        <div className='assistant-item__top-row'>
          <div className='assistant-item__info'>
            <h3 className='assistant-item__name'>{simplifiedAssistant[AssistantProperty.Name] || 'No name'}</h3>
            <p className='assistant-item__role'>{simplifiedAssistant[AssistantProperty.Role] || "(No role defined yet)"}</p>
          </div>
          <div className='assistant-item__actions'>
            {simplifiedAssistant[AssistantProperty.Name] && <button className='assistant-item__button u-link u-icon' onClick={onRun} title='Run the assistant'><IonIcon name="play-outline"></IonIcon></button>}
            <button className='assistant-item__button u-link u-icon' onClick={onEdit}><IonIcon name="create-outline"></IonIcon></button>
            <button className='assistant-item__button u-link u-icon' onClick={onDelete}><IonIcon name="trash-outline"></IonIcon></button>
          </div>
        </div>
        <p className='assistant-item__purpose'>{simplifiedAssistant[AssistantProperty.Purpose]}</p>
      </div>
    </div>
}
