import { Agent } from "./domain"
import { AnnaAssistantAssambler } from "./team/Anna Assistant Assembler"
import { CarlCommunicationCaptain } from "./team/Carl Communication Captain"
import { CesarContextCreator } from "./team/Cesar Context Creator"
import { EvaEngager } from "./team/Eva Engager"
import { NeoNamer } from "./team/Neo Namer"
import { PeerPurposePerfecter } from "./team/Peer Purpose Perfecter"
import { RobRoleRefiner } from "./team/Rob Role Refiner"
import { SaraScoper } from "./team/Sara Scoper"
import { TeaTaskTalker } from "./team/Tea Task Talker"
import { WillWritingWizard } from "./team/Will Writing Wizard"

const AVAILABLE_AGENTS = {
  [AnnaAssistantAssambler.id]: AnnaAssistantAssambler,
  [PeerPurposePerfecter.id]: PeerPurposePerfecter,
  [RobRoleRefiner.id]: RobRoleRefiner,
  [NeoNamer.id]: NeoNamer,
  [TeaTaskTalker.id]: TeaTaskTalker,
  [CesarContextCreator.id]: CesarContextCreator,
  [EvaEngager.id]: EvaEngager,
  [SaraScoper.id]: SaraScoper,
  [WillWritingWizard.id]: WillWritingWizard,
  [CarlCommunicationCaptain.id]: CarlCommunicationCaptain
}

export const AgentApplication = {
  agents: AVAILABLE_AGENTS,
  agentLead: AnnaAssistantAssambler,
  getById: (id: string): Agent | undefined => AVAILABLE_AGENTS[id]
}