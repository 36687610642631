import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAssistants } from "../assistant/AssistantContext"
import { AssistantCreation } from "../assistant/components/AssistantCreation"
import "./CreatePage.scss"
import { AppRoutes } from "./Routes"

export const CreatePage = () => {
  const { assistantId } = useParams()
  const { assistantState } = useAssistants()
  const currentAssistant = assistantState.assistants[assistantId ?? '']
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!currentAssistant) {
      navigate(AppRoutes.Overview())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return currentAssistant ? <AssistantCreation assistant={currentAssistant}></AssistantCreation> : <div></div>
}
