import { Assistant } from "../../assistant/domain"
import { ChatContent, ContentType } from "../domain/"
import { OptionsContentApplication } from "./ChatContentApplications/OptionsContentApplication"
import { PropertyContentApplication } from "./ChatContentApplications/PropertyContentApplication"
import { TextContentApplication } from "./ChatContentApplications/TextContentApplication copy 2"
import { ThinkingContentApplication } from "./ChatContentApplications/ThinkingContentApplication"

interface BasicContentApplication<T extends ChatContent> {
  append: (content: T, delta: string) => T
  convertToPrompt: (content: T, assistant: Assistant) => string
}

const getContentApplication = (contentType: ContentType): BasicContentApplication<ChatContent> => {
  switch (contentType) {
    case 'text':
      return TextContentApplication
    case 'property':
      return PropertyContentApplication
    case 'options':
      return OptionsContentApplication
    case 'thinking':
      return ThinkingContentApplication
  }
}

const appendToContent = (content: ChatContent, delta: string) => {
  return getContentApplication(content.type).append(content, delta)
}

const convertToPrompt = (content: ChatContent, assistant: Assistant): string => {
  return getContentApplication(content.type).convertToPrompt(content, assistant)
}

export const ChatContentApplication = {
  TextContentApplication,
  PropertyContentApplication,
  OptionsContentApplication,
  ThinkingContentApplication,
  appendToContent,
  convertToPrompt
}