import { HTMLProps } from 'react'
import './Image.scss'

interface ImageProps extends HTMLProps<HTMLPictureElement> {
    src: string,
    imageType: 'jpg' | 'png'
    alt: string,
    width: number,
    height: number,
    eager?: boolean
}

const createResolutionString = (baseName: string, imageType: string) => {
  return [1,2,3,4].map((value) => `${baseName}@${value}x.${imageType} ${value}x`).join(', ')
}

export const Image = ({ src, alt, width, height, imageType, eager, ...rest }: ImageProps) => {
  const loading = !eager ? 'lazy' : 'eager'
  const baseName = `/assets/${src}-${width}px`
  const webps = createResolutionString(baseName, 'webp')
  const images = createResolutionString(baseName, imageType)

  return <picture {...rest} className={`c-image ${rest.className ?? ''}`}>
  <source srcSet={webps} type="image/webp" />
  <source srcSet={images} type={`image/${imageType}`} />
  <img src={images[0]} alt={alt} width={width} height={height} loading={loading}></img>
</picture>
}
