import { AgentRetrievalFunction } from '../../agents/domain'
import { Assistant } from '../../assistant/domain'
import { Chat } from '../domain'
import "./ChatComponent.scss"
import { ChatSectionComponent } from './ChatSectionComponents/ChatSectionComponent'
import { ChatSectionNavigation } from './ChatSectionComponents/ChatSectionNavigation'

interface ChatComponentProps {
  chat: Chat,
  activeSectionId: string
  assistant: Assistant | undefined
  hasKey: boolean
  sendMessage: (chatId: string, sectionId: string, text: string, getAgentById: AgentRetrievalFunction) => void
  activateChat: (sectionId: string) => void
  getAgentById: AgentRetrievalFunction
  isMinimized: boolean,
  setMinimized: undefined | ((state: boolean) => void)
}

export const ChatComponent = ({hasKey, sendMessage, activateChat, assistant, chat, activeSectionId, getAgentById, isMinimized, setMinimized}: ChatComponentProps) => {
  
  const hasNavigation = chat.sections.length > 1

  return (<div className={`chat chat--${hasNavigation ? 'with-navigation' : 'without-navigation'}`}>
            {hasNavigation && <ChatSectionNavigation sections={chat.sections} activeSectionId={activeSectionId} selectSection={activateChat}></ChatSectionNavigation>}
            {chat.sections.map((section) => (
            <ChatSectionComponent isMinimized={isMinimized} setMinimized={setMinimized} getAgentById={getAgentById} hasKey={hasKey} activateChat={() => activateChat(section.id)} sendMessage={sendMessage} assistant={assistant} chatId={chat.id} section={section} activeSectionId={activeSectionId} key={section.id}></ChatSectionComponent>
          ))}
        </div>
  )
}
