import { ChatContentApplication } from "../chat/application";
import { MessagePath } from "../chat/domain";
import { ActionExecutionApi, AgentAction, AgentActionParameters } from "./domain";

export const ThinkingAction: AgentAction = {
  onNew: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters): boolean => {
    const thinking = ChatContentApplication.ThinkingContentApplication.create('')
    api.chat.addContent(path, thinking)
    return false
  },
  onUpdate: (api: ActionExecutionApi,  path: MessagePath, delta: string) => {
    api.chat.appendMessage(path, delta)
  },
  onClose: (api: ActionExecutionApi, path: MessagePath) => {
    const text = ChatContentApplication.TextContentApplication.create('')
    api.chat.addContent(path, text)
  },
  onMessageCompleted: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters, abort: boolean) => {}
}