import { DragonImages } from "../shared/components/DragonImage"

export const AvailableAssistantImages: DragonImages[] = [
  DragonImages.DRAGON_1,
  DragonImages.DRAGON_2,
  DragonImages.DRAGON_3,
  DragonImages.DRAGON_4,
  DragonImages.DRAGON_5,
  DragonImages.DRAGON_6,
  DragonImages.DRAGON_7,
  DragonImages.DRAGON_8,
  DragonImages.DRAGON_9,
  DragonImages.DRAGON_10,
  DragonImages.DRAGON_11,
  DragonImages.DRAGON_12,
  DragonImages.DRAGON_13,
  DragonImages.DRAGON_14,
  DragonImages.DRAGON_15,
  DragonImages.DRAGON_16,
  DragonImages.DRAGON_17,
  DragonImages.DRAGON_18,
  DragonImages.DRAGON_19,
  DragonImages.DRAGON_20,
  DragonImages.DRAGON_21,
  DragonImages.DRAGON_22,
  DragonImages.DRAGON_23,
  DragonImages.DRAGON_24,
  DragonImages.DRAGON_25,
  DragonImages.DRAGON_26,
  DragonImages.DRAGON_27,
  DragonImages.DRAGON_28,
]

export enum AssistantProperty {
  Purpose = 'purpose',
  Role = 'role',
  RoleDescription = 'roleDescription',
  Name = 'name',
  Tasks = 'tasks',
  Context = 'context',
  UserEngagement = 'engagement',
  Boundaries = 'boundaries',
  WritingStyle = 'writingStyle',
  Tone = 'tone'
}

export interface AssistantVersionInfo {
  versions: string[]
  selectedVersionIndex: number
}

export enum AssistantSection {
  start = 'start',
  purpose = 'purpose',
  role = 'role',
  name = 'name',
  tasks = 'tasks',
  context = 'context',
  engagement = 'engagement',
  scope = 'scope',
  writing = 'writing',
  tone = 'tone'
}

export const AssistantSectionName = (section: AssistantSection) => {
  switch (section) {
    case AssistantSection.start:
      return 'Welcome'
    case AssistantSection.purpose:
      return 'Purpose'
    case AssistantSection.role:
      return 'Role'
    case AssistantSection.name:
      return 'Name'
    case AssistantSection.tasks:
      return 'Tasks'
    case AssistantSection.context:
      return 'Context'
    case AssistantSection.engagement:
      return 'User Engagement'
    case AssistantSection.scope:
      return 'Scope and Boundaries'
    case AssistantSection.writing:
      return 'Format and Writing Style'
    case AssistantSection.tone:
      return 'Communication and Tone'
  }
}


export const PropertyName = (field: AssistantProperty): string => {
  switch (field) {
    case AssistantProperty.Purpose:
      return 'Purpose'
    case AssistantProperty.Role:
      return 'Role'
    case AssistantProperty.RoleDescription:
      return 'Role Description'
    case AssistantProperty.Name:
      return 'Name'
    case AssistantProperty.Tasks:
      return 'Tasks'
    case AssistantProperty.Context:
      return 'Context'
    case AssistantProperty.UserEngagement:
      return 'User Engagement Strategy'
    case AssistantProperty.Boundaries:
      return 'Scope and Boundaries'
    case AssistantProperty.WritingStyle:
      return 'Format and Writing Style'
    case AssistantProperty.Tone:
      return 'Conversation and Tone'
  }
}

export interface Assistant {
  id: string
  lastUpdated: number
  image: DragonImages | undefined
  chat: {
    constructionChatId: string
    testChatIds: string[]
  }
  data: {
    [AssistantProperty.Purpose]: AssistantVersionInfo
    [AssistantProperty.Role]: AssistantVersionInfo
    [AssistantProperty.RoleDescription]: AssistantVersionInfo
    [AssistantProperty.Name]: AssistantVersionInfo
    [AssistantProperty.Tasks]: AssistantVersionInfo
    [AssistantProperty.Context]: AssistantVersionInfo
    [AssistantProperty.UserEngagement]: AssistantVersionInfo
    [AssistantProperty.Boundaries]: AssistantVersionInfo
    [AssistantProperty.WritingStyle]: AssistantVersionInfo
    [AssistantProperty.Tone]: AssistantVersionInfo
  }
}

export interface SimplifiedAssistant {
  id: string
  image: DragonImages | undefined
  [AssistantProperty.Purpose]: string
  [AssistantProperty.Role]: string
  [AssistantProperty.RoleDescription]: string
  [AssistantProperty.Name]: string
  [AssistantProperty.Tasks]: string
  [AssistantProperty.Context]: string
  [AssistantProperty.UserEngagement]: string
  [AssistantProperty.Boundaries]: string
  [AssistantProperty.WritingStyle]: string
  [AssistantProperty.Tone]: string
}