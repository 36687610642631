import { useNavigate } from "react-router-dom"
import { useAiInfrastructure } from "../aiInfrastructure/AiInfrastructureContext"
import { Spinner } from "../shared/components/Spinner"
import "./StartPage.scss"
import { useEffect } from "react"
import { AppRoutes } from "./Routes"
import { useAssistants } from "../assistant/AssistantContext"

export const StartPage = () => {
  const { isInitialized, hasKey } = useAiInfrastructure()
  const { assistantState } = useAssistants()
  const navigate = useNavigate()

  useEffect(() => {
    if (isInitialized) {
      if (!hasKey && Object.keys(assistantState.assistants).length === 0) {
        navigate(AppRoutes.Welcome())
      } else {
        navigate(AppRoutes.Overview())
      }
    }
    // this effect will be called only once on creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  return <div className='start-page'>
    <Spinner></Spinner>
    <p>Application is loading...</p>
    </div>
}
