import { CustomMarkdown } from '../../shared/components/CustomMarkdown'
import { DragonImage } from '../../shared/components/DragonImage'
import { Agent, AgentContextFunction } from '../domain'
import "./AgentDetails.scss"

interface AgentInformationProps {
  agentContextFunction: AgentContextFunction
  agent: Agent
  showDescription: boolean
}

export const AgentDetails = ({agent, agentContextFunction, showDescription}: AgentInformationProps) => {
  const systemPrompt =  agent.systemPrompt(agentContextFunction);

  return (
        <div className={`agent-details`}>
          <div className='agent-details__introduction'>
            <DragonImage dragon={agent.image} size={128}></DragonImage>
            {showDescription && <p className='agent-details__description'>{agent.description}</p>}
          </div>
          <div className='agent-details__content'>
            <h4>System Prompt</h4>
            <div className='agent-details__prompt'><CustomMarkdown>{systemPrompt}</CustomMarkdown></div>
            {agent.initialMessage && <h4>Initial Message</h4>}
            {agent.initialMessage && <div className='agent-details__prompt'><CustomMarkdown>{agent.initialMessage}</CustomMarkdown></div>}
            {agent.firstInteraction && <h4>First Hidden Instruction</h4>}
            {agent.firstInteraction && <div className='agent-details__prompt'><CustomMarkdown>{agent.firstInteraction}</CustomMarkdown></div>}
          </div>
        </div>
  )
}
