
const loadKeyByPin = async (pin: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_EVENT_URL}/${pin}.json`)
    const json = (await response.json()) as { apiKey: string }
    if (json.apiKey) {
      return json.apiKey
    } else {
      throw Error('There is a problem with the event PIN. Please ask your instructor for advice.')
    }
  } catch (e) {
    throw Error('Could not validate PIN. Make sure it is correct.')
  }
}


export const AiInfrastructureApplication =  {
  loadKeyByPin
}