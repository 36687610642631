import { ChatCompletionMessageParam, ChatCompletionUserMessageParam } from 'openai/resources'
import { Agent, AgentRetrievalFunction } from '../agents/domain'
import { useAiInfrastructure } from '../aiInfrastructure/AiInfrastructureContext'
import { AssistantApplication } from '../assistant/AssistantApplication'
import { Assistant } from '../assistant/domain'
import { useChat } from './ChatContext'
import { ChatMessageApplication, ChatSectionApplication } from './application'
import { ChatApplication } from './application/ChatApplication'
import { Chat, ChatSection } from './domain'

export type onMessageContent = (chatId: string, sectionId: string, messageId: string, delta: string) => void
export type onMessageComplete = (chatId: string, sectionId: string, messageId: string, abort: boolean) => void

export const useAIChat = (assistant?: Assistant) => {
  const {hasKey, isBusy, request, abort} = useAiInfrastructure()
  const {addChat, deleteChat, addSection, getSection, addMessage, appendMessage, updateMessage} = useChat()
  const usedAssistant = assistant ?? AssistantApplication.create('')

  const createChat = (initialSections: ChatSection[] = []): Chat => {
    const newChat = ChatApplication.create(initialSections)
    addChat(newChat)
    return newChat
  }

  const removeChat = (chatId: string) => {
    deleteChat(chatId)
  }

  const createSection = (chatId: string, name: string, agent: Agent, getAgentById: AgentRetrievalFunction, onMessageContent?: onMessageContent, onMessageComplete?: onMessageComplete): ChatSection => {
    const newSection = ChatSectionApplication.create(name, agent.id)
    addSection(chatId, newSection)
    if (agent.initialMessage) {
      const botMessage = ChatMessageApplication.createBotMessage('', agent.name, 'Writing', undefined, false)
      addMessage(chatId, newSection.id, botMessage)
      if (onMessageContent) {
        onMessageContent(chatId, newSection.id, botMessage.id, agent.initialMessage)
      } else {
        appendMessage(chatId, newSection.id, botMessage.id, agent.initialMessage)
      }
      updateMessage(chatId, newSection.id, botMessage.id, true)
    }
    if (agent.firstInteraction) {
      const firstInteraction = agent.firstInteraction
      sendMessageInternal(chatId, newSection.id, firstInteraction, () => newSection, getAgentById, onMessageContent, onMessageComplete, true)
    }
    
    return newSection
  }

  const sendMessage = async (chatId: string, sectionId: string, text: string, getAgentById: AgentRetrievalFunction, onMessageContent?: onMessageContent, onMessageComplete?: onMessageComplete, userHidden: boolean = false) => {
    return sendMessageInternal(chatId, sectionId, text, getSection, getAgentById, onMessageContent, onMessageComplete, userHidden)
  }

  const sendMessageInternal = async (chatId: string, sectionId: string, text: string, getCorrectSection: (chatId: string, sectionId: string) => (ChatSection | undefined), getAgentById: AgentRetrievalFunction, onMessageContent?: onMessageContent, onMessageComplete?: onMessageComplete, userHidden: boolean = false) => {
    if (isBusy) {
      return
    }
    const section = getCorrectSection(chatId, sectionId)
    if (!section) {
      throw Error(`Invalid section ${sectionId} in Chat ${chatId}!`)
    }
    const currentPrompts = section ? ChatSectionApplication.convertToPrompts(section, usedAssistant, getAgentById) : []
    const prompt: ChatCompletionUserMessageParam = { role: 'user', content: text }
    const prompts: Array<ChatCompletionMessageParam> = [...currentPrompts, prompt]

    if (!userHidden) {
      const userMessage = ChatMessageApplication.createUserMessage(text)
      addMessage(chatId, sectionId, userMessage)
    }
    const mainAgent = getAgentById(section.mainAgentId)
    const botMessage = ChatMessageApplication.createBotMessage('', mainAgent?.name ?? 'Unknown Agent', 'Writing', undefined)
    addMessage(chatId, sectionId, botMessage)

    const onMessage = (delta: string) => {
      if (onMessageContent) {
        onMessageContent(chatId, sectionId, botMessage.id, delta)
      } else {
        appendMessage(chatId, sectionId, botMessage.id, delta)
      }
      
    }

    const onComplete = (abort: boolean) => {
      updateMessage(chatId, sectionId, botMessage.id, true)
      if (onMessageComplete) {
        onMessageComplete(chatId, sectionId, botMessage.id, abort)
      }
    }

    await request(prompts, onMessage, onComplete)
  }

  const abortMessage = () => {
    if (isBusy) {
      abort()
    }
  }

  return {isBusy, hasKey, createChat, removeChat, createSection, sendMessage, abortMessage}
}
