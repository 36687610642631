import OpenAI from 'openai'
import { ChatCompletionStream } from 'openai/lib/ChatCompletionStream'
import { ChatCompletionMessageParam } from 'openai/resources'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

const LOCAL_STORAGE_KEY = 'ai-infrastructure__key'

interface AiInfrastructureContextType {
  isInitialized: boolean,
  hasKey: boolean
  setKey: (key: string) => void
  isBusy: boolean
  request: (messages: Array<ChatCompletionMessageParam>, onContent: (delta: string) => void, onFinished?: (aborted: boolean) => void) => Promise<boolean>
  abort: () => void
}

// Create a context with an undefined initial value
const AiInfrastructureContext = createContext<AiInfrastructureContextType | undefined>(undefined)

// Define the ChatProvider component
const AiInfrastructureProvider = ({ children }: { children: ReactNode }) => {
  const [openai, setOpenai] = useState<OpenAI | undefined>()
  const [currentStream, setCurrentStream] = useState<ChatCompletionStream | null>(null)
  const isBusy = currentStream !== null
  const hasKey = openai !== undefined
  const [isInitialized, setIsInitialized] = useState(false)

  const initOpenAi = (key: string) => {
    const openai = new OpenAI({
      apiKey: key,
      dangerouslyAllowBrowser: true
    })
    setOpenai(openai)
  }

  useEffect(() => {
    const key = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (key) {
      initOpenAi(key)
    }
    setIsInitialized(true)
  }, [])

  const setKey = (key: string) => {
    if (key === '') {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
      setOpenai(undefined)
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, key)
      initOpenAi(key)
    }
  }

  const request = async (messages: Array<ChatCompletionMessageParam>, onContent: (delta: string) => void, onFinished?: (aborted: boolean) => void) => {
    if (currentStream || !openai) {
      return false
    }

    const stream = await openai.beta.chat.completions.stream({
      model: 'gpt-4o',
      messages,
      stream: true
    })
    setCurrentStream(stream)
  
    stream.on('error', (error) => {
      if (error.message.startsWith('401')) {
        setKey('')
      } else {
        console.error('An error occurred:', error);
      }
    })
    stream.on('content', (delta) => {
      onContent(delta)
    })

    stream.on('end', () => {
      setCurrentStream(null)
      if (onFinished) {
          onFinished(false)
      }
    })

    stream.on('abort', () => {
      setCurrentStream(null)
      if (onFinished) {
          onFinished(true)
      }
    })
    return true
  }

  const abort = () => {
    if (currentStream) {
      currentStream.abort()
    }
  }

  return <AiInfrastructureContext.Provider value={{ isInitialized, hasKey, setKey, isBusy, request, abort }}>{children}</AiInfrastructureContext.Provider>
}

const useAiInfrastructure = () => {
  const context = useContext(AiInfrastructureContext)
  if (!context) {
    throw new Error('useAiInfrastructure must be used within a AiInfrastructureProvider')
  }
  return context
}

export { AiInfrastructureProvider, useAiInfrastructure }