import { HTMLProps } from 'react'
import './Image.scss'
import { Image } from './Image'

export enum DragonImages {
  NO_DRAGON, DRAGON_0, DRAGON_1, DRAGON_2, DRAGON_3, DRAGON_4, DRAGON_5, DRAGON_6, DRAGON_7, DRAGON_8, DRAGON_9, DRAGON_10, DRAGON_11, DRAGON_12, DRAGON_13, DRAGON_14, DRAGON_15, DRAGON_16, DRAGON_17, DRAGON_18, DRAGON_19, DRAGON_20, DRAGON_21, DRAGON_22, DRAGON_23, DRAGON_24, DRAGON_25, DRAGON_26, DRAGON_27, DRAGON_28
}

const DRAGON_IMAGES = {
  [DragonImages.NO_DRAGON]: {name: 'no-assistant', color: 'greyed-out'},
  [DragonImages.DRAGON_0]: {name: 'dragon-0', color: 'unicorn'},
  [DragonImages.DRAGON_1]: {name: 'dragon-1', color: 'orange'},
  [DragonImages.DRAGON_2]: {name: 'dragon-2', color: 'red'},
  [DragonImages.DRAGON_3]: {name: 'dragon-3', color: 'blue'},
  [DragonImages.DRAGON_4]: {name: 'dragon-4', color: 'green'},
  [DragonImages.DRAGON_5]: {name: 'dragon-5', color: 'purple'},
  [DragonImages.DRAGON_6]: {name: 'dragon-6', color: 'black'},
  [DragonImages.DRAGON_7]: {name: 'dragon-7', color: 'black-white'},
  [DragonImages.DRAGON_8]: {name: 'dragon-8', color: 'brown'},
  [DragonImages.DRAGON_9]: {name: 'dragon-9', color: 'brown'},
  [DragonImages.DRAGON_10]: {name: 'dragon-10', color: 'green'},
  [DragonImages.DRAGON_11]: {name: 'dragon-11', color: 'green'},
  [DragonImages.DRAGON_12]: {name: 'dragon-12', color: 'orange'},
  [DragonImages.DRAGON_13]: {name: 'dragon-13', color: 'orange'},
  [DragonImages.DRAGON_14]: {name: 'dragon-14', color: 'orange-green'},
  [DragonImages.DRAGON_15]: {name: 'dragon-15', color: 'rose'},
  [DragonImages.DRAGON_16]: {name: 'dragon-16', color: 'pink'},
  [DragonImages.DRAGON_17]: {name: 'dragon-17', color: 'purple'},
  [DragonImages.DRAGON_18]: {name: 'dragon-18', color: 'purple'},
  [DragonImages.DRAGON_19]: {name: 'dragon-19', color: 'rainbow'},
  [DragonImages.DRAGON_20]: {name: 'dragon-20', color: 'red'},
  [DragonImages.DRAGON_21]: {name: 'dragon-21', color: 'red'},
  [DragonImages.DRAGON_22]: {name: 'dragon-22', color: 'red-blue'},
  [DragonImages.DRAGON_23]: {name: 'dragon-23', color: 'rose'},
  [DragonImages.DRAGON_24]: {name: 'dragon-24', color: 'green'},
  [DragonImages.DRAGON_25]: {name: 'dragon-25', color: 'green'},
  [DragonImages.DRAGON_26]: {name: 'dragon-26', color: 'green'},
  [DragonImages.DRAGON_27]: {name: 'dragon-27', color: 'white'},
  [DragonImages.DRAGON_28]: {name: 'dragon-28', color: 'yellow'}
}

interface DragonImageProps extends HTMLProps<HTMLPictureElement> {
    dragon: DragonImages
    size: number
}


export const DragonImage = ({ dragon, size, ...rest }: DragonImageProps) => {
  const dragonData = DRAGON_IMAGES[dragon]

  return <Image {...rest} src={dragonData.name} width={size} height={size} alt={`A small ${dragonData.color} dragon`} imageType='png'></Image>
}
