import { AssistantProperty } from '../assistant/domain'
import { ChatContentApplication } from '../chat/application'
import { MessagePath } from '../chat/domain'
import { ActionExecutionApi, AgentAction, AgentActionParameters } from './domain'

let lastProperty: AssistantProperty

export const AssistantAction: AgentAction = {
  onNew: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters): boolean => {
    const property = params['property'] as AssistantProperty
    lastProperty = property
    api.assistant.addVersion(property, '')
    const newContent =  ChatContentApplication.PropertyContentApplication.create(property)
    api.chat.addContent(path, newContent)
    return false
  },
  onUpdate: (api: ActionExecutionApi, path: MessagePath, delta: string) => {
    api.assistant.appendToLatestVersion(lastProperty, delta)
  },
  onClose: (api: ActionExecutionApi, path: MessagePath) => {
    api.assistant.cleanUpLatestVersion(lastProperty)
    const text = ChatContentApplication.TextContentApplication.create('')
    api.chat.addContent(path, text)
  },
  onMessageCompleted: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters, abort: boolean) => {}
}