import './App.scss';
import { AiInfrastructureProvider } from './aiInfrastructure/AiInfrastructureContext';
import { ChatProvider } from './chat/ChatContext';
import { Routes } from './pages/Routes';
import { AssistantProvider } from './assistant/AssistantContext';
import { TextEditorProvider } from './shared/TextEditor/TextEditorContext';
import { AgentProfileProvider } from './agents/components/AgentProfileContext';

const App = () => {
  return (
    <AiInfrastructureProvider>
      <AssistantProvider> 
        <ChatProvider>
          <TextEditorProvider>
            <AgentProfileProvider>
              <Routes></Routes>
            </AgentProfileProvider>
          </TextEditorProvider>
        </ChatProvider>
      </AssistantProvider>
    </AiInfrastructureProvider>
  );
};

export default App;
