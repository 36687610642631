import { useState } from 'react'
import "./ApiKeyForm.scss"
import { useAiInfrastructure } from '../AiInfrastructureContext'
import { Spinner } from '../../shared/components/Spinner'
import { AiInfrastructureApplication } from '../AiInstrastructureApplication'

interface ApiKeyProps {
  prefilledPin?: string
}

export const ApiKeyForm = ({prefilledPin}: ApiKeyProps) => {
  const {hasKey, setKey} = useAiInfrastructure()
  // const [pin, setPin] = useState(prefilledPin ?? '')
  const [apikey, setApiKey] = useState('')
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)

  const onRemoveKey = () => {
    setKey('')
  }

  const onSetKey = async () => {
    setError('')
    const input = apikey.trim()
    if (input === '') {
      setError('Please enter either a PIN or an API key')
      return
    }
    if (/^[0-9]{4}$/.test(input)) {
      setBusy(true)
      try {
        const apiKey = await AiInfrastructureApplication.loadKeyByPin(input)
        setApiKey('')
        setKey(apiKey)
      } catch (e) {
        setError((e as Error).message)
      } finally {
        setBusy(false)
      }
    } else if (apikey.trim() !== '') {
      setKey(apikey.trim())
    } else {
      setError('Please enter either a PIN or an API key.')
    }
  }

  const renderAlreadyHasKey = () => {
    return <div className='api-key-form__already-a-key'>
      <p>You already have an OpenAI API key saved.<br />If you want, you can remove it here.</p>
      <button className='primary' onClick={() => onRemoveKey()}>Remove Key</button>
    </div>
  }

  const renderApiKeyForm = () => {
    return <div className='api-key-form__key-form'>
      <input type='text' value={apikey} onChange={evt => setApiKey(evt.target.value)} placeholder='Event PIN / OpenAI API Key' onKeyDown={(e) => { if (e.key === 'Enter') { onSetKey() } }}></input>
      <button className='api-key-form__save-button primary' onClick={() => onSetKey()} disabled={busy}>{busy ? <Spinner small={true}></Spinner> : 'Save'}</button>
      {error && <p className='api-key-form__error error'>{error}</p>}
      <p className='api-key-form__note'><strong>Note:</strong> Any OpenAI API Key will be locally stored on the device in the browser's local storage. This application communicates directly with OpenAI's API servers and does not send information anywhere else.</p>
    </div>
  }

  return <div className='api-key-form'>{hasKey ? renderAlreadyHasKey() : renderApiKeyForm()}</div>
}
