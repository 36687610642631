import { AssistantProperty, AssistantSection } from "../assistant/domain"
import { ChatContent, MessagePath } from "../chat/domain"

export enum AgentActionTag {
  assistant = 'assistant',
  redirect = 'redirect',
  options = 'options',
  thinking = 'thinking'
}

export type AgentActionParameters = { [key: string]: string };

export type AgentActionParameterDescription = { name: string, required: boolean, validFunction: (name: string, value: string) => boolean}

export const AgentActionDefinition: { [key in AgentActionTag]: AgentActionParameterDescription[] } = {
  [AgentActionTag.assistant]: [
    { name: 'property', required: true, validFunction: (name: string, value: string) => Object.values(AssistantProperty).includes(value as AssistantProperty) }
  ],
  [AgentActionTag.redirect]: [
    { name: 'section', required: true, validFunction: (name: string, value: string) => Object.values(AssistantSection).includes(value as AssistantSection) }
  ],
  [AgentActionTag.options]: [
    { name: 'prefix', required: false, validFunction: (name: string, value: string) => true }
  ],
  [AgentActionTag.thinking]: []
}


export interface AgentAction {
  onNew: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters) => boolean, // return true, if Agent should remember this tag for completion
  onUpdate: (api: ActionExecutionApi, path: MessagePath, delta: string) => void,
  onClose: (api: ActionExecutionApi, path: MessagePath) => void,
  onMessageCompleted: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters, abort: boolean) => void
}

export interface ActionExecutionApi {
  assistant: {
    addVersion: (property: AssistantProperty, initialValue: string) => void
    selectVersion: (property: AssistantProperty, versionIndex: number) => void
    appendToLatestVersion: (property: AssistantProperty, delta: string) => void
    cleanUpLatestVersion: (property: AssistantProperty) => void
    findSection: (sectionName: AssistantSection) => string
    createSection: (sectionName: AssistantSection) => void
    selectSection: (sectionId: string) => void
  }
  chat: {
    addContent: (path: MessagePath, content: ChatContent) => void
    appendMessage: (path: MessagePath, delta: string) => void
    updateMessage: (path: MessagePath, complete: boolean) => void
  }
}