import { Assistant } from "../../../assistant/domain"
import { ChatContent, ChatOptionsContent } from "../../domain"


export const OptionsContentApplication = {
  create: (options: string[], prefix: string = ''): ChatOptionsContent => {
    return { type: 'options', prefix, options }
  },
  append: (content: ChatContent, delta: string): ChatContent => {
    const optionContent = (content as ChatOptionsContent)
    if (delta === '\n' && optionContent.options[optionContent.options.length -1].trim() !== '') {
      return {...optionContent, options: [...optionContent.options, '']} as ChatOptionsContent
    } else {
      return {...optionContent, options: optionContent.options.map((option, index) => index === optionContent.options.length - 1 ? option + delta : option)} as ChatOptionsContent
    }
  },
  convertToPrompt: (content: ChatContent, assistant: Assistant): string => {
    return (content as ChatOptionsContent).options.map((option, index) => `${index + 1}. ${option}`).join('\n')
  }
}