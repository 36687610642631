import { useEffect, useState } from 'react'
import { AgentApplication } from '../agents/AgentApplication'
import { useChat } from '../chat/ChatContext'
import { useAIChat } from '../chat/useAIChat'
import { createActionListeners } from './ActionListener'
import { AssistantApplication } from './AssistantApplication'
import { useAssistants } from './AssistantContext'
import { Assistant, AssistantSection } from './domain'

let lastTimeChatWasInitialized: number = 0

export const useAssistantRefiner = (assistant: Assistant) => {
  const {chatState,  appendMessage, updateMessage, addContent} = useChat()
  const {isBusy, createSection, sendMessage, abortMessage} = useAIChat(assistant)
  const { updateImage, addVersion, selectVersion, appendToLatestVersion, cleanUpLatestVersion } = useAssistants()
  const currentChatId = assistant.chat.constructionChatId
  const currentChat = chatState.chats[currentChatId]
  const currentSection = currentChat && currentChat.sections.length > 0 ? currentChat.sections[currentChat.sections.length - 1] : undefined
  const [currentSectionId, setCurrentSectionId] = useState<string>(currentSection?.id ?? '')
  const [isChatInitialized, setChatInitialized] = useState<boolean>(currentChat.sections.length > 0)
  const [tagError, setTagError] = useState<string[] | null>(null)

  const getMessageHandlers = (createNewSection: (sectionName: AssistantSection) => void) => {
    return createActionListeners({
      assistant,
      currentChat,
      addVersion,
      selectVersion,
      appendToLatestVersion,
      cleanUpLatestVersion,
      addContent,
      appendMessage,
      updateMessage,
      setCurrentSectionId,
      createNewSection,
      reportInvalidTagParameters: (errors: string[]) => {
        abortMessage()
        setTagError(errors)
        console.warn('Invalid tag parameters from AI:', errors)
      }
    })
  }

  const createNewSection = (assistantSection: AssistantSection) => {
    const sectionInfo = AssistantApplication.getSectionInformation(assistantSection)
    const {onMessageContent, onCompleteMessage} = getMessageHandlers((section: AssistantSection) => {})
    const section = createSection(currentChatId, sectionInfo.name, sectionInfo.agent, AgentApplication.getById, onMessageContent, onCompleteMessage)
    setCurrentSectionId(section.id)
    if (assistantSection === AssistantSection.tasks) {
      const image = AssistantApplication.selectAssistantImage()
      updateImage(assistant.id, image)
    }
  }

  useEffect(() => {
    if (!isChatInitialized && Date.now() - lastTimeChatWasInitialized > 500) {
      lastTimeChatWasInitialized = Date.now()
      setChatInitialized(true)
      createNewSection(AssistantSection.start)
    }
    // this effect will be called only once on creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  


  const send = async (text: string, hidden: boolean = false) => {
    const {onMessageContent, onCompleteMessage} = getMessageHandlers(createNewSection)
    return sendMessage(assistant.chat.constructionChatId, currentSectionId, text, AgentApplication.getById, onMessageContent, onCompleteMessage, hidden)
  }

  const abort = () => {
    if (isBusy) {
      abortMessage()
    }
  }

  useEffect(() => {
    if (tagError) {
      send(`There was at least one problem in the tag, you provided:
        ${tagError.join('\n')}
        Please try again. Don't mention that you did a mistake, just continue and use the tag correctly, as instructed!`, true)
      setTagError(null)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [tagError])


  return { isBusy, send, abort, currentSectionId, setCurrentSectionId }
}
