import { Chat, ChatSection } from "../domain"
import { v4 as uuidv4 } from 'uuid';

const create = (initialSections: ChatSection[] = []): Chat => {
  return {
      id: 'chat-' + uuidv4(),
      sections: initialSections
    }
}

export const ChatApplication = {
  create,
}