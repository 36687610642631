import { Assistant } from "../../../assistant/domain"
import { ChatContent, ChatThinkingContent } from "../../domain"

export const ThinkingContentApplication = {
  create: (text: string = ''): ChatThinkingContent => {
    return { type: 'thinking', text }
  },
  append: (content: ChatContent, delta: string): ChatContent => {
    return {...(content as ChatThinkingContent), text: (content as ChatThinkingContent).text + delta} as ChatThinkingContent
  },
  convertToPrompt: (content: ChatContent, assistant: Assistant): string => {
    return (content as ChatThinkingContent).text
  }
}
