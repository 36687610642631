import { HtmlHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react'
import "./ChatScrollComponent.scss"

interface ChatScrollComponentProps extends HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const ChatScrollComponent = ({ children, className, ...rest }: ChatScrollComponentProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const endRef = useRef<HTMLDivElement | null>(null)
  const [isAtBottom, setIsAtBottom] = useState(true)

  const scrollToBottom = () => {
    if (endRef.current) {
      endRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }


  const onChatScrollChanged = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      setIsAtBottom((scrollHeight - scrollTop) - clientHeight < 100)
    }
  }

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom()
    }
  }, [isAtBottom, children])


  return (<div {...rest} className={`chat-scroll ${className ?? ''}`} ref={containerRef} onScroll={onChatScrollChanged}>
    {children}
    <div className='chat-scroll__end' ref={endRef}></div>
    </div>)
}
