import { ChatCompletionMessageParam } from "openai/resources"
import { v4 as uuidv4 } from 'uuid'
import { AgentRetrievalFunction } from "../../agents/domain"
import { AssistantApplication } from "../../assistant/AssistantApplication"
import { Assistant } from "../../assistant/domain"
import { ChatSection } from "../domain"
import { ChatMessageApplication } from "./ChatMessageApplication"

const create = (name: string, agentId: string): ChatSection => {
  return {
      id: 'section-' + uuidv4(),
      name,
      mainAgentId: agentId,
      messages: []
    }
}

const convertToPrompts = (section: ChatSection, assistant: Assistant, getAgentById: AgentRetrievalFunction): Array<ChatCompletionMessageParam> => {
  const convertedMessages = section.messages.map(message => ChatMessageApplication.convertToPrompt(message, assistant))
  const agent = getAgentById(section.mainAgentId)
  const systemPrompt: Array<ChatCompletionMessageParam> = agent ? [{ role: 'system', content: agent.systemPrompt(AssistantApplication.createAgentContextFunction(assistant)) }] : []
  return [
    ...systemPrompt,
    ...convertedMessages
  ]
}

export const ChatSectionApplication = {
  create,
  convertToPrompts
}