import { AgentActionTag } from "../../actions/domain";
import { AssistantProperty, AssistantSection } from "../../assistant/domain";

interface AgentActionDescription {
  name: string,
  tag: string,
  usage: string,
  syntax: string,
  parameters: Array<{name: string, description?: string, required: boolean, allowedValues?: string}>
  examples: Array<{given: string, output: string}>
}

export type AgentActionTagDefinitions = { [key: string]: () => AgentActionDescription}

const tagPrompts: AgentActionTagDefinitions = {
  [AgentActionTag.options]: () => ({
    name: 'OPTIONS',
    tag: 'options',
    usage: 
`- Use, when to provide options or recommendations to the user to chose from.
- Options must be short (less than 6 words). Explanations go outside the options tags.
- Do not use for more than 6 options or very long names.
- Each option must be in a new line.
- Use a numbered list for 3+ options.
- Use just words if 1-2 options.`,
    syntax:`<options prefix='I chose '>[options list]</options>`,
    parameters: [
      {
        name: 'prefix',
        description: `Prefix of the user selection message, e.g. 'I chose '`,
        required: false
      }
    ],
    examples: [
      {
        given: 'Provide 3 options for fruits',
        output:
`<options prefix='I chose '>
1. Apple
2. Banana
3. Kiwi
</options>`
      },
      {
        given: `Provide one option 'Continue'`,
        output: '<options>Continue</options>'
      }
    ]
  }),
  [AgentActionTag.redirect]: () => ({
    name: 'REDIRECT',
    tag: 'redirect',
    usage: `- Redirect to another Chat-Section.`,
    syntax: `<redirect section='[section name]'></redirect>`,
    parameters: [
      {
        name: 'section',
        required: true,
        allowedValues: Object.values(AssistantSection).join(', ')}
    ],
    examples: [
      {
        given: 'Redirect to tasks',
        output: `<redirect section='tasks'></redirect>`
      },
      {
        given: 'Redirect to purpose',
        output: `<redirect section='purpose'></redirect>`
      },
    ]
  }),
  [AgentActionTag.assistant]: () => ({
    name: 'ASSISTANT PROPERTY',
    tag: 'assistant',
    usage:
`- Use to fill or change an assistant's property.
- Do not use for thinking outputs.`,
    syntax: `<assistant property='[property name]'>[content]</assistant>`,
    parameters: [
      {
        name: 'property',
        required: true,
        allowedValues: Object.values(AssistantProperty).join(', ')
      }
    ],
    examples: [
      {
        given: `Set the property name to 'David Designer'`,
        output: `<assistant property='name'>David Designer</assistant>`
      },
      {
        given: `Set the property purpose to 'Help users achieve better work-life balance'`,
        output:
`<assistant property='purpose'>Help users achieve better work-life balance</assistant>`
      },
    ]
  }),
  [AgentActionTag.thinking]: () => ({
    name: 'THINKING',
    tag: 'thinking',
    usage:
`- When you need to think (e.g. step-by-step) and produce non-user relevant context.
- Don't use it within other tags or when producing user relevant context.`,
    syntax: `<thinking>[thinking-output]</thinking>`,
    parameters: [],
    usageNotes: ``,
    examples: [
      {
        given: 'Generate 5 two-word alliteration names',
        output:
`<thinking>
1. Designer
2. Developer
3. Producer
4. Architect
5. Copywriter
</thinking>
What about the following names:
1. Diana Designer
2. Dave Developer
3. Peter Producer
4. Archie Architect
5. Carl Copywriter`
      }
    ]
  })
}

export const AgentActionPrompts = {
  format: ({name, tag, syntax, usage, parameters, examples}: AgentActionDescription) => `
## ${name.toUpperCase()} TAG
Tag: <${tag}>

**Usage:**
${usage}

**Syntax:**
${syntax}
${parameters.length > 0 ? `
**Parameters:**
${parameters.map(param => `- ${param.name} (${param.required ? 'REQUIRED!' : 'Optional'}): ${param.description ?? ''}${param.allowedValues ? ` Allowed Values: ${param.allowedValues}` : ''}`).join('\n')}
`: ''}
**Examples:**
${examples.map(({given, output}) => 
`${given}:
${output}`).join('\n\n')}`,
  tagPrompts
}

// `### Ask ###
// To approach a problem from a different standpoint with a different system prompt (agent), use the following syntax:
// \`\`\`
// <ask agent='[name of the agent]' context='[list of context keys, comma separated]'>
// [input and instructions for the agent that uses the different system prompt.]
// </ask>
// \`\`\`

// The name of the agent must be one of the following: ${Object.values(AgentName).map(name => `'${name}'`).join(', ')}. No other names are allowed!
// The list of context keys consists of a comma-separated list of context keys, chosen from the following list: assistant.purpose, assistant.role, assistant, section.NeoNamer, section.RobRoleRefiner.

// Examples:
// - If you want to ask the agent 'Neo Namer' about a good name for an assistant based on the assistant's purpose and role:
// \`\`\`
// <ask agent='Neo Namer' context='assistant.purpose,assistant.role'>
// Please provide me an excellent name for the assistant based on its purpose and role.
// </ask>
// \`\`\`
// `