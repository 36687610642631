import { AssistantSection } from "../assistant/domain"
import { ChatContentApplication } from "../chat/application"
import { MessagePath } from "../chat/domain"
import { ActionExecutionApi, AgentAction, AgentActionParameters } from "./domain"

export const RedirectAction: AgentAction = {
  onNew: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters): boolean => {
  const section = params['section']
  const content = ChatContentApplication.TextContentApplication.create(`Great. Let's move on to '${section}'`)
  api.chat.addContent(path, content)
    return true
  },
  onUpdate: (api: ActionExecutionApi, path: MessagePath, delta: string) => {},
  onClose: (api: ActionExecutionApi, path: MessagePath) => {
    const text = ChatContentApplication.TextContentApplication.create('')
    api.chat.addContent(path, text)
  },
  onMessageCompleted: (api: ActionExecutionApi, path: MessagePath, params: AgentActionParameters, abort: boolean) => {
    const sectionName = params['section'] as AssistantSection
    const sectionId = api.assistant.findSection(sectionName)
    if (sectionId) {
      api.assistant.selectSection(sectionId)
    } else {
      api.assistant.createSection(sectionName)
    }
  }
}