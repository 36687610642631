import { ReactNode, createContext, useContext, useReducer } from 'react';
import { ChatContentApplication } from './application';
import { ChatContent, ChatMessage, ChatSection } from './domain';
import { Chat } from './domain/Chat';

const LOCAL_STORAGE_KEY = 'chat__key'

// Define the ChatState interface
interface ChatState {
  chats: {[id: string]: Chat};
}

// Define the Action types
type Action =
  | { type: 'ADD_CHAT'; chat: Chat }
  | { type: 'DELETE_CHAT'; chatId: string }
  | { type: 'ADD_SECTION'; chatId: string, section: ChatSection }
  | { type: 'ADD_MESSAGE'; chatId: string, sectionId: string, message: ChatMessage }
  | { type: 'ADD_CONTENT'; chatId: string, sectionId: string, messageId: string; content: ChatContent }
  | { type: 'APPEND_MESSAGE'; chatId: string, sectionId: string, messageId: string; delta: string }
  | { type: 'UPDATE_MESSAGE'; chatId: string, sectionId: string, messageId: string; complete: boolean }

const appendToMessage = (message: ChatMessage, delta: string): ChatMessage => {
  const currentContent = message.content.length > 0 ? message.content[message.content.length - 1] : null
  if (currentContent === null) {
    console.warn(`Message ${message.id} has no content to append to.`)
    return message
  }
  const newContent = ChatContentApplication.appendToContent(currentContent, delta)
  return {...message, content:[...message.content.slice(0, -1), newContent ]}
}

const updateChatInState = (state: ChatState, chat: Chat) => {
  return {...state, chats: {...state.chats, [chat.id]: chat}}
}

const updateSectionInChat = (state: ChatState, chatId: string, sectionId: string, manipulate: (section: ChatSection) => ChatSection) => {
  const chat = state.chats[chatId]
  const section = chat.sections.find(section => section.id === sectionId)
  if (section) {
    const newSection = manipulate(section)
    const newChat = {...chat, sections: chat.sections.map(section => section.id === newSection.id ? newSection : section)}
    return updateChatInState(state, newChat)
  }
  return state
}

const updateMessageInSection = (section: ChatSection, messageId: string, manipulate: (message: ChatMessage) => ChatMessage): ChatSection => {
  const message = section.messages.find(message => message.id === messageId)
  if (message) {
    const newMessage = manipulate(message)
    return {...section, messages: section.messages.map(message => message.id === newMessage.id ? newMessage : message)}
  }
  return section
}

const updateLocalStorage = (state: ChatState) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
  return state
}

// Define the chatReducer function
const chatReducer = (state: ChatState, action: Action): ChatState => {
  switch (action.type) {
    case 'ADD_CHAT':
      return updateLocalStorage(updateChatInState(state, action.chat))
    case 'DELETE_CHAT':
      return updateLocalStorage({...state, chats: Object.fromEntries(Object.entries(state.chats).filter(([key]) => key !== action.chatId))})
    case 'ADD_SECTION':
      const chat = state.chats[action.chatId]
      const newChat = {...chat, sections: [...chat.sections, action.section]}
      return updateLocalStorage(updateChatInState(state, newChat))
    case 'ADD_MESSAGE':
      return updateLocalStorage(updateSectionInChat(state, action.chatId, action.sectionId, (section) => ({...section, messages: [...section.messages, action.message]})))
    case 'ADD_CONTENT':
      return updateSectionInChat(state, action.chatId, action.sectionId, (section) => {
        return updateMessageInSection(section, action.messageId, (message) => {
          return {...message, content: [...message.content, action.content]}
        })
      })
    case 'APPEND_MESSAGE':
      return updateSectionInChat(state, action.chatId, action.sectionId, (section) => {
        return updateMessageInSection(section, action.messageId, (message) => {
          return appendToMessage(message, action.delta)
        })
      })
    case 'UPDATE_MESSAGE':
      return updateLocalStorage(updateSectionInChat(state, action.chatId, action.sectionId, (section) => {
        return updateMessageInSection(section, action.messageId, (message) => {
          return (message.sender === 'bot') ? {...message, completed: action.complete} : message
        })
      }))
    default:
      throw new Error('Unhandled action type')
  }
}

interface ChatContextType {
  chatState: ChatState
  addChat: (chat: Chat) => void
  deleteChat: (chatId: string) => void
  addSection: (chatId: string, section: ChatSection) => void
  getSection: (chatId: string, sectionId: string) => ChatSection | undefined
  addMessage: (chatId: string, sectionId: string, message: ChatMessage) => void
  addContent: (chatId: string, sectionId: string, messageId: string, content: ChatContent) => void
  appendMessage: (chatId: string, sectionId: string, messageId: string, delta: string) => void
  updateMessage: (chatId: string, sectionId: string, messageId: string, complete: boolean) => void
}

// Create a context with an undefined initial value
const ChatContext = createContext<ChatContextType | undefined>(undefined)

// Define the ChatProvider component
const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [chatState, dispatch] = useReducer(chatReducer, { chats: {}, ...localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!) : {}  })

  const addChat = (chat: Chat) => {
    dispatch({ type: 'ADD_CHAT', chat })
  }

  const deleteChat = (chatId: string) => {
    dispatch({ type: 'DELETE_CHAT', chatId })
  }

  const addSection = (chatId: string, section: ChatSection) => {
    dispatch({ type: 'ADD_SECTION', chatId, section })
  }

  const addMessage = (chatId: string, sectionId: string, message: ChatMessage) => {
    dispatch({ type: 'ADD_MESSAGE', chatId, sectionId, message })
  }

  const addContent = (chatId: string, sectionId: string, messageId: string, content: ChatContent) => {
    dispatch({type : 'ADD_CONTENT', chatId, sectionId, messageId, content})
  }

  const appendMessage = (chatId: string, sectionId: string, messageId: string, delta: string) => {
    dispatch({ type: 'APPEND_MESSAGE', chatId, sectionId, messageId, delta })
  }

  const updateMessage = (chatId: string, sectionId: string, messageId: string, complete: boolean) => {
    dispatch({ type: 'UPDATE_MESSAGE', chatId, sectionId, messageId, complete })
  }

  const getSection = (chatId: string, sectionId: string): ChatSection | undefined => {
    const chat = chatState.chats[chatId]
    if (chat) {
      return chat.sections.find(section => section.id === sectionId)
    }
    return undefined
  }

  return <ChatContext.Provider value={{ chatState, addChat, deleteChat, addSection, getSection, addMessage, addContent, appendMessage, updateMessage }}>{children}</ChatContext.Provider>
}

// Define a custom hook to use the chat context
const useChat = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider')
  }
  return context
}

export { ChatProvider, useChat };

