import { useAgentProfile } from '../../../agents/components/AgentProfileContext'
import { AgentRetrievalFunction } from '../../../agents/domain'
import { Assistant } from '../../../assistant/domain'
import { ChatContent, ChatPropertyContent, ChatSection } from '../../domain'
import { PropertyComponent } from '../ChatContentComponents/PropertyComponent'
import { ChatMessageComponent } from '../ChatMessageComponent'
import { ChatScrollComponent } from './ChatScrollComponent'
import "./ChatSectionComponent.scss"

interface ChatSectionComponentProps {
  section: ChatSection,
  activeSectionId: string,
  assistant: Assistant | undefined
  chatId: string
  hasKey: boolean,
  sendMessage: (chatId: string, sectionId: string, text: string, getAgentById: AgentRetrievalFunction) => void
  activateChat: () => void,
  getAgentById: AgentRetrievalFunction,
  isMinimized: boolean,
  setMinimized: undefined | ((state: boolean) => void)
}

export const ChatSectionComponent = ({ hasKey, sendMessage, activateChat, isMinimized, setMinimized, assistant, chatId, section, activeSectionId, getAgentById }: ChatSectionComponentProps) => {
  // const [expanded, setExpanded] = useState(true)
  const {openAgentProfile} = useAgentProfile()
  const active = activeSectionId === section.id
  const mainAgent = getAgentById(section.mainAgentId)
  const propertyContent = !isMinimized ? [] : section.messages.reduce((acc, msg) => [...acc, ...msg.content.filter(content => content.type === 'property')], [] as ChatContent[])
  const uniqueContent = (propertyContent as ChatPropertyContent[]).filter((content, index, self) => index === self.findIndex(c => c.key === content.key))

  const onMinimizeChat = () => {
    if (setMinimized) {
      setMinimized(!isMinimized)
    }
  }

  const onProfile = () => {
    if (mainAgent) {
      openAgentProfile(mainAgent)
    }
  }

  return (
      <div className={`chat-section chat-section--${active ? 'active' : 'inactive'}`}>
        <div className='chat-section__header'>
          <div className='chat-section__main-info'>
            <h2 className='chat-section__title'>{mainAgent?.name ?? 'Unknown Agent'}</h2>
            {mainAgent && <span>(<button className='u-link' onClick={onProfile}>Profile</button>)</span>} 
          </div>
          {!!setMinimized && <button className='u-link chat-section__activate-button' onClick={onMinimizeChat}>{isMinimized ? 'Show Chat' : 'Show Property'}</button>}
        </div>
        <ChatScrollComponent className={`chat-section__history chat-section__history--${active ? 'active' : 'non-active'}`}>
            {!isMinimized && section.messages.map((msg, index, messages) => (
                <ChatMessageComponent hasKey={hasKey} mainAgent={mainAgent} getAgentById={getAgentById} sendMessage={sendMessage} assistant={assistant} chatId={chatId} sectionId={section.id} key={msg.id} message={msg} nextMessage={index < messages.length - 1 ? messages[index+1] : undefined}></ChatMessageComponent>
              ))}
            {isMinimized && uniqueContent.length === 0 && <div className='chat-section__no-content'>No properties to edit here, yet.</div>}
            {isMinimized && uniqueContent.map(content => {
              return <PropertyComponent key={content.key} content={content} assistant={assistant} completed={true}></PropertyComponent>
            })}
          </ChatScrollComponent>
    </div>
  )
}
