import { DragonImage, DragonImages } from '../../shared/components/DragonImage'
import { Assistant } from '../domain'
import { AssistantItem } from './AssistantItem'
import "./AssistantList.scss"

interface AssistantListProps {
  assistants: Assistant[]
}

export const AssistantList = ({assistants}: AssistantListProps) => {
  const sortedAssistants = assistants.sort((a, b) => (b.lastUpdated - a.lastUpdated))

  return <div className={`assistant-list assistant-list--${assistants.length === 0 ? 'empty' : 'non-empty'}`}>
      {sortedAssistants.length === 0 && <div className='assistant-list__no-assistants'>
        <DragonImage dragon={DragonImages.NO_DRAGON} size={128}></DragonImage>
        <p className='assistant-list__no-assistants-text'>You currently have no AI Assistants yet.<br /><br />Click the plus-button on the right top<br />to create your first assistant.</p>
      </div>}
      {sortedAssistants.map((assistant) => (
        <AssistantItem key={assistant.id} assistant={assistant} />
      ))}
    </div>
}
