import IonIcon from "@reacticons/ionicons"
import { useNavigate } from "react-router-dom"
import { AgentProfile } from "../../agents/components/AgentProfile"
import { useAgentProfile } from "../../agents/components/AgentProfileContext"
import { useAiInfrastructure } from "../../aiInfrastructure/AiInfrastructureContext"
import { NoKeyWarning } from "../../aiInfrastructure/components/NoKeyWarning"
import { useChat } from "../../chat/ChatContext"
import { ChatComponent } from "../../chat/components/ChatComponent"
import { ChatInput } from "../../chat/components/ChatInput"
import { AppRoutes } from "../../pages/Routes"
import { TextEditor } from "../../shared/TextEditor/TextEditor"
import { useTextEditor } from "../../shared/TextEditor/TextEditorContext"
import { AssistantApplication } from "../AssistantApplication"
import { Assistant, AssistantProperty } from "../domain"
import { useAssistantRefiner } from "../useAssistantRefiner"
import "./AssistantCreation.scss"
import { AgentApplication } from "../../agents/AgentApplication"
import { useState } from "react"

interface AssistantCreationProps {
  assistant: Assistant
}

export const AssistantCreation = ({assistant}: AssistantCreationProps) => {
  const { isTextEditorOpen, textEditorInformation } = useTextEditor()
  const { isAgentProfileOpen, agent, closeAgentProfile } = useAgentProfile()
  const {hasKey} = useAiInfrastructure()
  const { chatState } = useChat()
  const currentAssistantChat = chatState.chats[assistant.chat.constructionChatId]
  const {isBusy, send, abort, currentSectionId, setCurrentSectionId } = useAssistantRefiner(assistant)
  const navigate = useNavigate()
  const currentName = assistant.data[AssistantProperty.Name].versions.length > 0 ? AssistantApplication.getVersion(assistant, AssistantProperty.Name) : ''
  const isTestAvailable = assistant.data[AssistantProperty.Name].versions.length > 0
  const [minimized, setMinimized] = useState<boolean>(false)
  
  const onBack = () => {
    navigate(AppRoutes.Overview())
  }

  const onRun = () => {
    navigate(AppRoutes.Run(assistant))
  }

  return <div className='assistant-creation u-page'>
    {isTextEditorOpen && <TextEditor initialValue={textEditorInformation!.initialValue} title={textEditorInformation!.title} onSave={textEditorInformation!.onSave} onClose={textEditorInformation!.onClose}></TextEditor>}
    {isAgentProfileOpen && agent && <AgentProfile agent={agent} onClose={() => closeAgentProfile()}></AgentProfile>}
    <header>
      <button className='assistant-creation__back u-link u-icon' onClick={onBack}><IonIcon name="chevron-back-outline"></IonIcon></button>
      <h2>{currentName ? `Refining ${currentName}` : 'Creating a new assistant'}</h2>
      <button className={`assistant-creation__test-button u-link u-icon assistant-creation__test-button--${isTestAvailable ? 'available' : 'unavailable'}`} disabled={!isTestAvailable} onClick={onRun}><IonIcon name='play-outline'></IonIcon></button>
    </header>
    <main>
      <ChatComponent isMinimized={minimized} setMinimized={setMinimized} getAgentById={AgentApplication.getById} hasKey={hasKey} activateChat={setCurrentSectionId} sendMessage={(chatId: string, sectionId: string, text: string) => send(text)} chat={currentAssistantChat} activeSectionId={currentSectionId} assistant={assistant}></ChatComponent>
    </main>
    {!minimized && <footer>
      {hasKey ? <ChatInput isBusy={isBusy} onAbortMessage={abort} onSendMessage={send}></ChatInput> : <NoKeyWarning></NoKeyWarning>}
    </footer>}
    </div>
}
