import { AgentRetrievalFunction } from '../../../agents/domain'
import { ChatMessage, ChatOptionsContent, ChatTextContent, MessagePath } from '../../domain'
import './OptionsComponent.scss'

interface OptionsComponentProps {
  content: ChatOptionsContent
  nextMessage: ChatMessage | undefined
  path: MessagePath,
  sendMessage: (chatId: string, sectionId: string, text: string, getAgentById: AgentRetrievalFunction) => void,
  getAgentById: AgentRetrievalFunction
  hasKey: boolean
}

export const OptionsComponent = ({ hasKey, sendMessage, getAgentById, path, content, nextMessage }: OptionsComponentProps) => {
  const nextMessageContent = nextMessage && nextMessage.sender === 'user' ? nextMessage.content[0] : undefined
  const nextMessageText = nextMessageContent && nextMessageContent.type === 'text' ? (nextMessageContent as ChatTextContent).text : ''

  const onSelectOption = (option: string) => {
    sendMessage(path.chatId, path.sectionId, content.prefix + option, getAgentById)
  }
  
  return (
    <div className={`options`}>
      {content.options.filter(option => option.trim() !== '').map(option => {
        return <button className={`options__item options__item--${nextMessageText.indexOf(option) !== -1 ? 'selected' : 'unselected'}`} disabled={nextMessage !== undefined || !hasKey} key={option} onClick={() => onSelectOption(option)}>{option}</button>
      })}
    </div>
  )
}
