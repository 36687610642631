import { RouteObject, useRoutes } from 'react-router-dom'
import { StartPage } from './StartPage'
import { WelcomePage } from './WelcomePage'
import { OverviewPage } from './OverviewPage'
import { CreatePage } from './CreatePage'
import { SettingsPage } from './SettingsPage'
import { Assistant } from '../assistant/domain'
import { RunPage } from './RunPage'

export const AppRoutes = {
  Home: () => '/',
  Welcome: () => `/welcome`,
  Overview: () => `/overview`,
  Create: (assistant: Assistant) => `/${assistant.id}/create`,
  Run: (assistant: Assistant) => `/${assistant.id}/run`,
  Settings: () => '/settings'
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <StartPage />
  },
  {
    path: '/welcome',
    element: <WelcomePage />
  },
  {
    path: '/overview',
    element: <OverviewPage />
  },
  {
    path: '/:assistantId/create',
    element: <CreatePage />
  },
  {
    path: '/:assistantId/run',
    element: <RunPage />
  },
  {
    path: '/settings',
    element: <SettingsPage />
  }
]

export const Routes = () => {
  const routesElement = useRoutes(routes)
  return routesElement
}