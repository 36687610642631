import { useAIChat } from '../chat/useAIChat'
import { AssistantApplication } from './AssistantApplication'
import { useAssistants } from './AssistantContext'
import { Assistant } from './domain'

export const useAssistantManager = () => {
  const {createChat, removeChat} = useAIChat()
  const { assistantState, addAssistant, deleteAssistant } = useAssistants()
  

  const createAssistant = (): Assistant => {
    const chat = createChat()
    const assistant = AssistantApplication.create(chat.id)
    addAssistant(assistant)
    
    return assistant
  }

  const removeAssistant = (assistantId: string) => {
    const assistant = assistantState.assistants[assistantId]
    if (assistant) {
      removeChat(assistant.chat.constructionChatId)
      assistant.chat.testChatIds.forEach(chatId => removeChat(chatId))
      deleteAssistant(assistant.id)
    }
  }

  return { createAssistant, removeAssistant }
}
