import { useEffect, useRef, useState } from 'react'
import "./ChatInput.scss"
import IonIcon from '@reacticons/ionicons'

interface ChatInputProps {
  isBusy: boolean,
  onSendMessage: (text: string) => void
  onAbortMessage: () => void
}

export const ChatInput = ({isBusy, onSendMessage, onAbortMessage}: ChatInputProps) => {
  const [input, setInput] = useState('')
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onSendButtonClick = () => {
    if (isBusy) {
      onAbortMessage()
    } else {
      if (input.trim() === '') {
        return
      }
      const messageText = input
      onSendMessage(messageText)
      setInput('')
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set height to match content
    }
  }, [input]);

  return (<div className='chat-input'>
     <textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder='Write your message'
        disabled={isBusy}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault()
            e.stopPropagation()
            onSendButtonClick()
          }
        }}
        ref={textAreaRef}
        rows={1}
      />
      <button className='chat-input__send primary u-icon' onClick={onSendButtonClick} disabled={input.trim() === '' && !isBusy}><IonIcon name={isBusy ? 'stop' : 'arrow-up-outline'}></IonIcon></button>
    </div>
  )
}
