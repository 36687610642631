import IonIcon from '@reacticons/ionicons'
import { ChatSection } from '../../domain'
import "./ChatSectionNavigation.scss"

interface ChatSectionNavigationProps {
  sections: ChatSection[],
  activeSectionId: string,
  selectSection: (sectionId: string) => void
}

export const ChatSectionNavigation = ({sections, activeSectionId, selectSection}: ChatSectionNavigationProps) => {
  const currentSectionIndex = sections.findIndex(section => section.id === activeSectionId)
  const currentSection = currentSectionIndex >= 0 ? sections[currentSectionIndex] : undefined
  const currentSectionTitle = currentSection?.name ?? 'Unknown Section'
  const previousSection = currentSectionIndex > 0 ? sections[currentSectionIndex - 1] : undefined
  const previousSectionTitle = previousSection?.name ?? ''
  const nextSection = currentSectionIndex >= 0 && currentSectionIndex < sections.length - 1 ? sections[currentSectionIndex + 1] : undefined
  const nextSectionTitle = nextSection?.name ?? ''

  const onNextSection = () => {
    if (nextSection) {
      selectSection(nextSection.id)
    }
  }

  const onPreviousSection = () => {
    if (previousSection) {
      selectSection(previousSection.id)
    }
  }
  return (<div className='chat-section-navigation'>
      {previousSection ? <button className='chat-section-navigation__back u-link u-icon' onClick={onPreviousSection}><IonIcon name="chevron-back-outline"></IonIcon><span className='chat-section-navigation__button-text u-button-text'>{previousSectionTitle}</span></button> : <div className='chat-section-navigation__back'></div>}
      <h2 className='chat-section-navigation__title'>{currentSectionTitle}</h2>
      {nextSection ? <button className='chat-section-navigation__forward u-link u-icon' onClick={onNextSection}><span className='chat-section-navigation__button-text u-button-text'>{nextSectionTitle}</span><IonIcon name="chevron-forward-outline"></IonIcon></button> : <div className='chat-section-navigation__forward'></div>}
    </div>
  )
}
