import { MessagePath } from "../chat/domain"
import { AssistantAction } from "./AssistantAction"
import { OptionsAction } from "./OptionsAction"
import { RedirectAction } from "./RedirectAction"
import { ThinkingAction } from "./ThinkingAction"
import { ActionExecutionApi, AgentAction, AgentActionParameters, AgentActionTag } from "./domain"

const getAction = (tag: string): AgentAction | null => {
  switch (tag) {
    case AgentActionTag.assistant:
      return AssistantAction
    case AgentActionTag.options:
      return OptionsAction
    case AgentActionTag.redirect:
      return RedirectAction
    case AgentActionTag.thinking:
      return ThinkingAction
  }
  return null
}

let collectedTags: Array<{tag: string, params: AgentActionParameters}> = []

export const ActionApplication = {

  onNewTag: (api: ActionExecutionApi, path: MessagePath, tag: string, params: AgentActionParameters) => {
    const action = getAction(tag)
    if (action && action.onNew(api, path, params)) {
      collectedTags = [...collectedTags, {tag, params}]
    }
  },
  onUpdateTagContent: (api: ActionExecutionApi, path: MessagePath, tag: string, delta: string) => {
    const action = getAction(tag)
    if (action) {
      action.onUpdate(api, path, delta)
    }
  },
  onCloseTag: (api: ActionExecutionApi, path: MessagePath, tag: string) => {
    const action = getAction(tag)
    if (action) {
      action.onClose(api, path)
    }
  },
  onMessageComplete: (api: ActionExecutionApi, path: MessagePath, abort: boolean) => {
    if (collectedTags.length > 0) {
      collectedTags.forEach(({tag, params}) => {
        const action = getAction(tag)
        if (action) {
          action.onMessageCompleted(api, path, params, abort)
        }
      })
      collectedTags = []
    }
  }

}