import { ReactNode, createContext, useContext, useState } from 'react';
import { Agent } from '../domain';

interface AgentProfileContextType {
  isAgentProfileOpen: boolean,
  agent: Agent | null
  openAgentProfile: (agent: Agent) => void
  closeAgentProfile: () => void
}

const AgentProfileContext = createContext<AgentProfileContextType | undefined>(undefined)

const AgentProfileProvider = ({ children }: { children: ReactNode }) => {
  const [agent, setAgent] = useState<null | Agent>(null)
  const isAgentProfileOpen = agent !== null

  const openAgentProfile = (agent: Agent) => {
    setAgent(agent)
  }

  const closeAgentProfile = () => {
    setAgent(null)
  }

  return <AgentProfileContext.Provider value={{ isAgentProfileOpen, agent, openAgentProfile, closeAgentProfile }}>{children}</AgentProfileContext.Provider>
}

// Define a custom hook to use the chat context
const useAgentProfile = () => {
  const context = useContext(AgentProfileContext)
  if (!context) {
    throw new Error('useTextEditor must be used within a TextEditor')
  }
  return context
}

export { AgentProfileProvider, useAgentProfile };

