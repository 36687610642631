import { AgentApplication } from '../../agents/AgentApplication'
import { useAgentProfile } from '../../agents/components/AgentProfileContext'
import { Agent, AgentRetrievalFunction } from '../../agents/domain'
import { Assistant } from '../../assistant/domain'
import { CustomMarkdown } from '../../shared/components/CustomMarkdown'
import { DragonImage, DragonImages } from '../../shared/components/DragonImage'
import { BotMessage, ChatContent, ChatMessage, ChatOptionsContent, ChatPropertyContent, ChatTextContent, ChatThinkingContent } from '../domain'
import { OptionsComponent } from './ChatContentComponents/OptionsComponent'
import { PropertyComponent } from './ChatContentComponents/PropertyComponent'
import { ThinkingComponent } from './ChatContentComponents/ThinkingComponent'
import './ChatMessageComponent.scss'

interface ChatMessageComponentProps {
  assistant: Assistant | undefined
  mainAgent: Agent | undefined
  chatId: string,
  sectionId: string,
  message: ChatMessage
  nextMessage: ChatMessage | undefined
  hasKey: boolean
  sendMessage: (chatId: string, sectionId: string, text: string, getAgentById: AgentRetrievalFunction) => void
  getAgentById: AgentRetrievalFunction
}

export const ChatMessageComponent = ({ hasKey, sendMessage, getAgentById, assistant, chatId, sectionId, message, nextMessage, mainAgent }: ChatMessageComponentProps) => {
  const {openAgentProfile} = useAgentProfile()
  const botMessage = message.sender === 'bot' ? (message as BotMessage) : null
  const joiningAgent = botMessage && botMessage.joiningAgentId ? AgentApplication.getById(botMessage.joiningAgentId) : null
  const image = joiningAgent ? joiningAgent.image : (botMessage ? ( mainAgent ? mainAgent.image : DragonImages.NO_DRAGON) : DragonImages.DRAGON_0)

  const renderContent = (content: ChatContent, index: number, array: ChatContent[]) => {
    switch (content.type) {
      case 'text':
        return <div className='chat-message__content' key={index}><CustomMarkdown>{(content as ChatTextContent).text}</CustomMarkdown></div>
      case 'property':
        return <PropertyComponent assistant={assistant} key={index} content={(content as ChatPropertyContent)} completed={botMessage ? botMessage.completed : true}></PropertyComponent>
      case 'options':
        return <OptionsComponent hasKey={hasKey} getAgentById={getAgentById} sendMessage={sendMessage}  path={{chatId, sectionId, messageId: message.id}} key={index} content={(content as ChatOptionsContent)} nextMessage={nextMessage}></OptionsComponent>
      case 'thinking':
        return <ThinkingComponent agentName={message.senderName} content={(content as ChatThinkingContent)} key={index} isInCreation={!((botMessage && botMessage.completed) || index < array.length - 1)}></ThinkingComponent>
    }
  }

  const onOpenAgentProfile = () => {
    if (botMessage) {
      if (joiningAgent) {
        openAgentProfile(joiningAgent)
      } else if (mainAgent) {
        openAgentProfile(mainAgent)
      }
    }
  }

  return (
    <div className={`chat-message chat-message--${message.sender} chat-message--${botMessage ? botMessage.activity : 'no-activity'}`}>
      <DragonImage dragon={image} size={24} className='chat-message__dragon' onClick={onOpenAgentProfile}></DragonImage>
      <div className={`chat-message__body`}>
        <h3 className='chat-message__header'>
          <span className='chat-message__name'>{message.senderName}</span>
          {joiningAgent && <span className='chat-message__joining-agent'> as {joiningAgent.name}</span>}
        </h3>
        {message.content.map(renderContent)}
        <div className='chat-message__footer'>
          {botMessage && !botMessage.completed && <p className='chat-message__activity'>{botMessage.activity}...</p>}
          <p className='chat-message__time'>{botMessage && !botMessage.completed ? '' : new Date(message.timestamp).toLocaleTimeString(undefined)}</p>
        </div>
      </div>
    </div>
  )
}
