import IonIcon from "@reacticons/ionicons"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { NoKeyWarning } from "../../aiInfrastructure/components/NoKeyWarning"
import { useChat } from "../../chat/ChatContext"
import { ChatComponent } from "../../chat/components/ChatComponent"
import { ChatInput } from "../../chat/components/ChatInput"
import { useAIChat } from "../../chat/useAIChat"
import { Agent } from "../domain"
import "./AgentChat.scss"
import { AgentDetails } from "./AgentDetails"
import { AgentPromptBar } from "./AgentPromptBar"
import { useAgentProfile } from "./AgentProfileContext"
import { AgentProfile } from "./AgentProfile"

interface AgentChatProps {
  agent: Agent
  chatId: string
  sectionId: string
}

export const AgentChat = ({agent, chatId, sectionId}: AgentChatProps) => {
  const { chatState } = useChat()
  const chat = chatState.chats[chatId]
  const {isBusy, hasKey, sendMessage, abortMessage} = useAIChat()
  const navigate = useNavigate()
  const [activeView, setActiveView] = useState<'chat' | 'prompt'>('chat')
  const { isAgentProfileOpen, closeAgentProfile } = useAgentProfile()
  
  const onBack = () => {
    navigate(-1)
  }

  return <div className='agent-chat u-page'>
    {isAgentProfileOpen && agent && <AgentProfile agent={agent} onClose={() => closeAgentProfile()}></AgentProfile>}
    <header>
      <button className='agent-chat__back u-link u-icon' onClick={onBack}><IonIcon name="chevron-back-outline"></IonIcon></button>
      <h2>{activeView === 'chat' ? `Chat with ${agent.name}` : `Details of ${agent.name}`}</h2>
      <div className='u-header-placeholder'></div>
    </header>
    <main>
      <div className='agent-chat__switch'>
        <button className={`agent-chat__switch-option agent-chat__switch-option--${activeView === 'chat' ? 'active' : 'inactive'}`} onClick={() => setActiveView('chat')}>Chat</button>
        <button className={`agent-chat__switch-option agent-chat__switch-option--${activeView === 'prompt' ? 'active' : 'inactive'}`} onClick={() => setActiveView('prompt')}>Prompt</button>
      </div>
      {activeView === 'chat' && <ChatComponent isMinimized={false} setMinimized={undefined} getAgentById={() => agent} hasKey={hasKey} activateChat={() => {}} sendMessage={sendMessage} chat={chat} activeSectionId={sectionId} assistant={undefined}></ChatComponent>}
      {activeView === 'prompt' && <AgentDetails showDescription={false} agent={agent} agentContextFunction={() => ''}></AgentDetails>}
    </main>
    <footer>
      {!hasKey &&  <NoKeyWarning></NoKeyWarning>}
      {hasKey && activeView === 'chat' && <ChatInput isBusy={isBusy} onAbortMessage={abortMessage} onSendMessage={(text: string) => sendMessage(chatId, sectionId, text, () => agent)}></ChatInput>}
      {hasKey && activeView === 'prompt' && <AgentPromptBar agent={agent} agentContextFunction={() => ''}></AgentPromptBar>}
    </footer>
    </div>
}
