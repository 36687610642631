import { Agent, AgentContextFunction } from '../domain'
import "./AgentPromptBar.scss"

interface AgentPromptBarProps {
  agentContextFunction: AgentContextFunction
  agent: Agent
}

export const AgentPromptBar = ({agent, agentContextFunction}: AgentPromptBarProps) => {
  const systemPrompt =  agent.systemPrompt(agentContextFunction).trim();

  const copyToClipboard = (prompt: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = prompt;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  const onCopySystemPrompt = () => {
    copyToClipboard(systemPrompt);
  }

  const onCopyChatPrompt = () => {
    copyToClipboard(`${systemPrompt}\n\nDo you understand? A simple yes or no is sufficient.`);
  }

  return (
        <div className={`agent-prompt-bar`}>
          <button onClick={onCopyChatPrompt}>Copy Chat Prompt</button>
          <button onClick={onCopySystemPrompt} className='primary'>Copy System Prompt</button>
        </div>
  )
}
