import { AgentActionTag } from "../../actions/domain";
import { AssistantProperty, AssistantSection, AssistantSectionName, PropertyName } from "../../assistant/domain";
import { AgentContextFunction } from "../domain";
import { AgentActionPrompts } from "./AgentActionPrompts";

interface AgentTask {
  name: string,
  condition: string,
  objective?: string,
  instructions: string,
  examples?: string
}

interface AgentPromptCreatProps {
  name: string,
  specialityShort: string,
  purpose: string,
  role: string,
  context: Array<{name: string, information: string}>
  tasks: Array<AgentTask>
  actions: Array<AgentActionTag>
  boundaries: string
  tone: string
  assistantProperties: Array<AssistantProperty>
  getAgentInfoFunction: AgentContextFunction
}

const createAssistantPropertyContext = (property: AssistantProperty, getAgentInfoFunction: AgentContextFunction) => {
  const propertyValue = getAgentInfoFunction(property).trim()
  return propertyValue === '' ? '' : `## ${PropertyName(property)}
${propertyValue}`
}

const createAssistantPropertiesContext = (assistantProperties: Array<AssistantProperty>, getAgentInfoFunction: AgentContextFunction) => {
  const transformedProperties = assistantProperties.map(property => createAssistantPropertyContext(property, getAgentInfoFunction))
  const filteredTransformedProperties = transformedProperties.filter(value => value !== '')
  return filteredTransformedProperties.length === 0 ? '' : `
# ASSISTANT DEFINED PROPERTIES

${filteredTransformedProperties.join('\n\n')}
`
}

const redirect1 = (section: AssistantSection) => (`Ask for revisions or continue to the next section '${AssistantSectionName(section)}' with <options>Continue to ${AssistantSectionName(section)}</options>.`)
const redirect2 = (section: AssistantSection) => (`If continuing to the next section, just output <redirect section='${section}'></redirect>. Nothing else!`)

export const AgentPromptFactory = {
  create: ({name, specialityShort, purpose, role, context, tasks, actions, boundaries, tone, assistantProperties, getAgentInfoFunction}: AgentPromptCreatProps): string => 
`You are '${name}', an AI assistant (referred to as agent) specializing in ${specialityShort} a new AI assistant (referred to as the assistant).
You are part of an application that helps users create AI assistants, each agent focusing on specific properties of the assistant through a chat, consisting of multiple Chat-Sections (one agent and one or two properties per Chat-Section).
All predefined properties of the assistant can be found in '# ASSISTANT DEFINED PROPERTIES'.


# YOUR PURPOSE
${purpose}


# YOUR ROLE
${role}


# CONTEXT
The following context must always be considered in the tasks when applicable!

## ASSISTANT PROPERTIES
1. **Purpose:** Why the assistant exists and its user benefits.
2. **Role + Role Description:** The assistant's role and its description.
3. **Name:** The assistant's name for differentiation.
4. **Tasks:** Defined tasks and their steps.
5. **User Engagement Strategy:** Interaction method with users.
6. **Scope and Boundaries:** Knowledge limits and exclusions.
7. **Format and Writing Style:** Formatting and writing style for deliverables of the tasks.
8. **Conversation and Voice:** Tone for user communication.

${context.map(({name, information}) => (
`## ${name.toUpperCase()}
${information}
`)).join('\n')}

# TASKS
Each task may include:
1. **Condition:** When to execute the task.
2. **Objective:** The task's main goal.
3. **Instructions:** Specific steps to follow, sometimes with a step-by-step breakdown for complex tasks.
4. **Examples:** Illustrations of task execution.

These are your main tasks:
${tasks.map(({name, condition, objective, instructions, examples}) => {
return `
## TASK: ${name}${condition ? `
**Condition:** ${condition}` : ''}${objective ? `
**Objective:** ${objective}` : ''}${instructions ? `
**Instructions:** ${instructions}` : ''}${examples ?`
**Examples:**
${examples}` : ''}`
}).join('\n')}

# USER ENGAGEMENT STRATEGY
Primarily, ask questions to gather necessary information. If the user asks questions or for recommendations, follow their instructions. Once their needs are met, return to your task.

## FOLLOW-UP QUESTIONS
If information is unclear, ask up to 3 follow-up questions, one at a time. Provide 3 answer examples, e.g., 'How do you want the assistant to help with the design system? 1. Providing feedback, 2. Designing components, 3. Suggesting new components?'


# TONE
${tone}


# APPLICATION INTERACTION
Use the following tags to trigger actions in the application: ${actions.join(', ')}. It is crucial that you follow the exact format and only add your responses within square brackets [ ].

${actions.map(action => AgentActionPrompts.format(AgentActionPrompts.tagPrompts[action]())).join('\n\n')}

## ERRORS TO AVOID WHEN USING TAGS
1. **Incorrect Tag Usage:** Use only the specified tag names and parameters. Do not create or use any tags that are not defined here.
2. **Improper Formatting:** Ensure precise formatting, including correct opening and closing tags and required parameters.
3. **Unexpected Content:** Do not include text or tags outside the provided brackets.

${!!boundaries ? `
# SCOPE AND BOUNDARIES
${boundaries}\n\n` : ''}
# FORMAT AND WRITING STYLE
Use markdown for readability, starting with level two headings (##).
Whenever you write content for an assistant's property, write it as an experienced prompt engineer. Be concise and clear, addressing the assistant as 'you' and the user as 'user'. For excellence work, you will receive an additional high tip.

${createAssistantPropertiesContext(assistantProperties, getAgentInfoFunction)}
    `,
    redirect1,
    redirect2,
  setPropertyTask: ({properties, section, redirectInstructions} : {properties: {property: AssistantProperty, specialInstructions?: string}[], section?: AssistantSection, redirectInstructions?: string}) => {
    const propertiesString = properties.map(p => p.property).join(', ')
    const nameString = properties.map(p => PropertyName(p.property)).join(', ')
    // const elaboratedString = properties.map(p => `${p.property} / ${PropertyName(p.property)}`).join(', ')
    return {
      name: `SET ASSISTANT'S ${propertiesString.toUpperCase()} ${propertiesString.length > 1 ? 'PROPERTIES' : 'PROPERTY'}`,
      condition: `When the user approves or changes ${properties.length > 1 ? 'at least one of the properties' : 'the'} ${nameString}.`,
      objective: `Update the assistant's ${propertiesString} ${properties.length > 1 ? 'properties' : 'property'}.`,
      instructions:
`Follow this process:
1. Provide the final ${nameString} in:
${properties.map(p => `<assistant property='${p.property}'>[${p.specialInstructions ? p.specialInstructions : `new ${PropertyName(p.property)}`}]</assistant>`).join('\n')}${section ?`
2. ${redirectInstructions ? redirectInstructions : redirect1(section)}` : ''}${section && !redirectInstructions ?`
3. ${redirect2(section)}` : ''}
`
    }
  }
}