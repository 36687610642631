import IonIcon from '@reacticons/ionicons'
import { useState } from 'react'
import './TextEditor.scss'

interface TextEditorProps {
  initialValue: string,
  title: string,
  onSave: (newText: string) => void
  onClose: () => void
}

export const TextEditor = ({initialValue, title, onSave, onClose}: TextEditorProps) => {
  const [value, setValue] = useState<string>(initialValue)
  
  return <div className={`text-editor u-overlay`}>
          <header className='text-editor__header'>
            <div className='u-header-placeholder'></div>
            <h2>{title}</h2>
            <button className='text-editor__close u-link u-icon' onClick={() => onClose()}><IonIcon name="close"></IonIcon></button>
          </header>
          <main>
            <textarea onChange={evt => setValue(evt.target.value)} value={value}></textarea>
          </main>
          <footer className='text-editor__footer'>
            <button onClick={() => onClose()}>{value === initialValue ? 'Cancel' : 'Discard'}</button>
            <button className='primary' disabled={value === initialValue} onClick={() => onSave(value)}>Save</button>
          </footer>
      </div>
}
