import "./LocalStorageUsage.scss"

export const LocalStorageUsage = () => {

  const onResetApp = () => {
    const reset = window.confirm('Reset will delete all assistants, chats and the API key. Are you sure you want to reset the app?')
    if (reset) {
      localStorage.clear()
      window.location.reload()
    }
  }

  const calculateUsedLocalStorage = () => {
    return Object.entries(localStorage).reduce((total, [key, value]) => total + (key.length + (value ? value.length : 0)), 0)
  };

  const usedStorage = calculateUsedLocalStorage()


  let usedStorageText = `${usedStorage} Bytes`
  if (usedStorage > 1024) {
    usedStorageText = `${(usedStorage / 1024).toFixed(1)} KB`
  }
  if (usedStorage > 1048576) {
    usedStorageText = `${(usedStorage / 1048576).toFixed(1)} MB`
  }

  return <div className='local-storage-usage'>
      <div className='local-storage-usage__actual-usage'>Usage: {usedStorageText} / 5 MB</div>
      <button className='danger' onClick={onResetApp}>Reset the App</button>
    </div>
}
