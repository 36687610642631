import IonIcon from '@reacticons/ionicons'
import { useState } from 'react'
import { useAssistants } from '../../../assistant/AssistantContext'
import { Assistant, PropertyName } from '../../../assistant/domain'
import { CustomMarkdown } from '../../../shared/components/CustomMarkdown'
import { useTextEditor } from '../../../shared/TextEditor/TextEditorContext'
import { ChatPropertyContent } from '../../domain'
import './PropertyComponent.scss'

interface PropertComponentProps {
  assistant: Assistant | undefined
  content: ChatPropertyContent
  completed: boolean
}

export const PropertyComponent = ({ assistant, content, completed }: PropertComponentProps) => {
  const { openTextEditor, closeTextEditor } = useTextEditor()
  const {addVersion, selectVersion} = useAssistants()
  const versions = assistant?.data[content.key]?.versions ?? ['Error']
  const currentVersion = assistant?.data[content.key]?.selectedVersionIndex ?? 0
  const [viewedVersion, setViewedVersion] = useState(versions.length > 0 ? currentVersion : 0)
  const currentViewedProperty = versions[viewedVersion] ?? ''

  const changeVersion = (newVersion: number) => {
    const correctVersion = Math.max(0, Math.min(versions.length - 1, newVersion))
    setViewedVersion(correctVersion)
  }

  const onSave = (value: string) => {
    if (assistant) {
      const newVersion = versions.length
      addVersion(assistant.id, content.key, value)
      setViewedVersion(newVersion)
    }
    closeTextEditor()
  }
  const onSelect = (index: number) => {
    if (assistant) {
      selectVersion(assistant.id, content.key, index)
    }
  }

  const setEditMode = () => {
    openTextEditor(`Edit Property: ${PropertyName(content.key)}`, currentViewedProperty, onSave, () => closeTextEditor())
  }
  
  return (
    <div className={`property-component`}>
      <h4>{PropertyName(content.key)}</h4>
      <div className='property-component__preview'>
        <CustomMarkdown>{currentViewedProperty}</CustomMarkdown>
      </div>
      {completed && <div className='property-component__button-bar'>
        <div className='property-component__action-buttons'>
          {assistant && viewedVersion !== currentVersion && <button className='u-link' onClick={() => onSelect(viewedVersion)}>Use Version</button>}
          <button disabled={!assistant} className='u-link u-icon' onClick={() => setEditMode()}><IonIcon name='create-outline'></IonIcon></button>
        </div>
        <div className='property-component__version-bar'>
          <button className='u-link u-icon' disabled={viewedVersion === 0} onClick={() => changeVersion(viewedVersion - 1)}><IonIcon name='chevron-back-outline'></IonIcon></button>
          <p className={`property-component__viewed-version property-component__viewed-version--${viewedVersion === currentVersion ? 'current' : 'other'}`}>{viewedVersion + 1}</p>
          <button className='u-link u-icon' disabled={viewedVersion === versions.length - 1}  onClick={() => changeVersion(viewedVersion + 1)}><IonIcon name='chevron-forward-outline'></IonIcon></button>
        </div>
      </div>}
    </div>
  )
}
