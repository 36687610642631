import IonIcon from "@reacticons/ionicons"
import { useNavigate } from "react-router-dom"
import { ApiKeyForm } from "../aiInfrastructure/components/ApiKeyForm"
import { AppRoutes } from "./Routes"
import "./SettingsPage.scss"
import { LocalStorageUsage } from "../aiInfrastructure/components/LocalStorageUsage"

export const SettingsPage = () => {
  const navigate = useNavigate()

  const onBack = () => {
    navigate(AppRoutes.Overview())
  }

  return <div className='settings-page u-page'>
    <header>
      <button className='overview-page__back u-link u-icon' onClick={onBack}><IonIcon name="chevron-back-outline"></IonIcon></button>
      <h2>Settings</h2>
      <div className='u-header-placeholder'></div>
    </header>
    <main>
      <div className="settings-page__group">
        <h3>OpenAI API Key</h3>
        <ApiKeyForm></ApiKeyForm>
      </div>
      <div className="settings-page__group">
        <h3>Local Storage Usage</h3>
        <LocalStorageUsage></LocalStorageUsage>
      </div>
    </main>
    </div>
}
